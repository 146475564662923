import React, { useEffect, useState } from 'react'
import { Card, Pagination, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OrderService from '../../../services/order.service';
import withLoader from '../../../layout/loader/withLoader';

const MainOrders = () => {
    const [showDelete, setShowDelete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderData, setOrderData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRows, setTotalRows] = useState();

    const limit = 10
    const handlePageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    };
    const AllOrders = async () => {
        try {
            const response = await OrderService.GetAllProducts(pageNumber, limit);
            console.log("Response", response.data.data);
            setOrderData(response.data.data);
            setTotalRows(response.data.totalOrders)
        } catch (e) {
            console.log("e", e);
        }
    };
    useEffect(() => {
        AllOrders();
    }, []);
    return (
        <>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">ORDERS</h2>
                </div>
            </div>
            <div lg={12} className="w-100">
                {/* {showDelete && <ReceiptModel show={showDelete} onHide={() => setShowDelete(false)} />} */}
                <Card className="custom-card overflow-hidden">
                    <Card.Body>
                        <div className="responsive">
                            <Table bordered className="text-nowrap table-shopping-cart mb-0">
                                <thead className="text-muted">
                                    <tr className="small text-uppercase">
                                        <th scope="col">ID</th>
                                        <th scope="col">User Name</th>
                                        <th scope="col">Super Markets</th>
                                        <th scope="col" className="wd-120">
                                            Items
                                        </th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Token</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" className="text-center ">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderData.map((list, index) => (
                                        <tr key={index} data-index={index}>
                                            <td>{list._id}</td>
                                            <td>{list.userId.userName}</td>
                                            <td>
                                                <div className="media">
                                                    <div className="card-aside-img">
                                                        <img
                                                            src={list.storeId?.storeImage}
                                                            alt="img"
                                                            className="img-sm"
                                                        />
                                                    </div>
                                                    <div className="media-body my-auto">
                                                        <div className="card-item-desc mt-0">
                                                            <h6 className="font-weight-semibold mt-0 text-uppercase">
                                                                {list.storeId?.storeName}
                                                            </h6>

                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{list.productCount}</td>
                                            <td>{list.orderDate}
                                            </td>
                                            <td>{list.token}</td>
                                            <td>
                                                <button
                                                    className={`btn btn-sm btn-${list.orderStatus === "Accept" ? "success" : list.orderStatus === "Pending" ? "warning" : "danger"} text-white`}
                                                    style={{
                                                        width: "110px",
                                                        textAlign: "center",
                                                        cursor: "default",
                                                    }}
                                                >
                                                    <i class={`fa fa-${list.orderStatus === "Accept" ? "check" : list.orderStatus === "Pending" ? "clock" : "close"}`}></i> {list.orderStatus}
                                                </button>
                                            </td>
                                            <td className="text-center">
                                                <Link to={`/team/orders/${list._id}`} state={{
                                                    Id: list._id,
                                                    Name: list.userId?.userName
                                                }} className='btn btn-primary btn-sm' >
                                                    <i className='fas fa-eye'></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <Pagination className="mt-3 justify-content-end">
                            <Pagination.Prev onClick={() => setPageNumber(currentPage - 1)} disabled={currentPage === 1} />
                            {Array.from({ length: Math.ceil(totalRows / limit) }).map((_, index) => (
                                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setPageNumber(currentPage + 1)} disabled={currentPage === Math.ceil(totalRows / limit)} />
                        </Pagination>
                    </Card.Body>
                </Card>
            </div >
        </>
    );
}

export default withLoader(MainOrders);
