import serverCall from '../../serverCall'

const GetAllUsers = async (search, page, limit, data) => {
    try {
        const response = await serverCall.get(`/users/admin/get-all?search=${search}&page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const StoreList = async (data) => {
    try {
        const response = await serverCall.get('/store/get-storeList', data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const UserGetQrCode = async (id) => {
    try {
        const response = await serverCall.get(`/qrcode/get-user-qrcode?userId=${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const UserQrCode = async (id, token) => {
    try {
        const response = await serverCall.post(`/qrcode/generate-qrcode?userId=${id}&token=${token}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const OneUserDetails = async (id, page, limit, data) => {
    try {
        const response = await serverCall.get(`/cart/admin/get-user-orders?userId=${id}&page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const CreateUser = async (email, password, type, storeId) => {
    try {
        const response = await serverCall.post(`/auth/admin/create-user?email=${email}&password=${password}&userType=${type}&storeId=${storeId}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const UserService = {
    GetAllUsers,
    OneUserDetails,
    CreateUser,
    StoreList,
    UserQrCode,
    UserGetQrCode
};
export default UserService;