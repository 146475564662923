import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import DatePicker from 'react-multi-date-picker';
import OfferImg from '../../../assets/img/offerImg.png'
import { CheckBox } from '@mui/icons-material';
import moment from 'moment';
const AddOffer = (props) => {
    const { formik2, show, handleDialogClose, isUpdate, isChecked, handleCheckboxChange, handleBannerImage, previewImageBanner } = props
    const handleDateChnage = (date) => {
        formik2.setFieldValue("expiryDate", date);
    };
    return (
        <Modal
            {...props}
            show={show}
            onHide={handleDialogClose}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <h4 className="mt-3 align-items-center">{isUpdate ? "UPDATE" : "ADD"}</h4>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="col-122">
                    <div className="card custom-card">
                        <form
                            onSubmit={formik2.handleSubmit}
                        >
                            <div className="col-12">
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Expire date: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    {/* <Form.Control
                                        type="date"
                                        name="expiryDate"
                                        placeholder="Expire Date"
                                        value={formik2.values.expiryDate}
                                        onChange={formik2.handleChange}
                                    /> */}
                                    <DatePicker
                                        style={{
                                            padding: "18px 111px",
                                            border: "1px solid #e8e8f7",
                                            textAlign: "center",
                                        }}
                                        value={formik2.values.expiryDate}
                                        onChange={(date) => handleDateChnage(date)}
                                        // onChange={formik2.handleChange}
                                        format="DD/MM/YYYY"
                                        placeholder={
                                            isUpdate
                                                ? formik2.values.expiryDate
                                                : "Select Expire Date"
                                        }
                                        name="expiryDate"
                                    // minDate={new Date()}
                                    // plugins={[<TimePicker position="top" />]}
                                    />
                                    {props.formik2.errors.expiryDate &&
                                        props.formik2.touched.expiryDate ? (
                                        <p className="text-start error">
                                            {props.formik2.errors.expiryDate}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                ><Form.Label
                                    style={{
                                        textAlign: "start",
                                        color: "#000",
                                        marginTop: "15px",
                                    }}
                                >
                                        Discount: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="discount"
                                        value={formik2.values.discount}
                                        onChange={formik2.handleChange}
                                    />
                                    {props.formik2.errors.discount &&
                                        props.formik2.touched.discount ? (
                                        <p className="text-start error">
                                            {props.formik2.errors.discount}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                ><Form.Label
                                    style={{
                                        textAlign: "start",
                                        color: "#000",
                                        marginTop: "15px",
                                    }}
                                >
                                        Token: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="Number"
                                        name="token"
                                        value={formik2.values.token}
                                        onChange={formik2.handleChange}
                                    />
                                    {props.formik2.errors.token &&
                                        props.formik2.touched.token ? (
                                        <p className="text-start error">
                                            {props.formik2.errors.token}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group controlId="validationFormik101"
                                    className="position-relative" style={{
                                        textAlign: "start",
                                        color: "#000",
                                        marginTop: "15px",
                                    }}>
                                    <Form.Check
                                        type="checkbox"
                                        label="Show As Banner"
                                        name='isBanner'
                                        checked={isChecked}
                                        value={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Group>
                                {isChecked && (
                                    <>
                                        <Form.Group
                                            controlId="validationFormik101"
                                            className="position-relative"
                                        ><Form.Label
                                            style={{
                                                textAlign: "start",
                                                color: "#000",
                                                marginTop: "15px",
                                            }}
                                        >
                                                Image: <span className="tx-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="bannerImage"
                                                // value={props.formik2.values.bannerImage}
                                                onChange={handleBannerImage}
                                            />
                                            {props.formik2.errors.bannerImage &&
                                                props.formik2.touched.bannerImage ? (
                                                <p className="text-start error">
                                                    {props.formik2.errors.bannerImage}
                                                </p>
                                            ) : null}
                                        </Form.Group>

                                    </>
                                )}
                                {previewImageBanner && (
                                    <div>
                                        {/* <p>Preview:</p> */}
                                        <img src={previewImageBanner} alt="Preview" height={150} />
                                    </div>
                                )}
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <Button
                                    type="submit"
                                    style={{
                                        display: "flex",
                                        marginLeft: "auto",
                                        marginRight: "15px",
                                        marginTop: "20px",
                                        textAlign: 'center',
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
}

export default AddOffer
