import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const AddSuperMarketModel = (props) => {
  const {
    formik,
    show,
    handleDialogClose,
    isUpdate,
    handleImageChange,
    previewImage,
    handleCoordinateChange,
  } = props;
  return (
    <Modal
      {...props}
      show={show}
      onHide={handleDialogClose}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h4 className="mt-3 align-items-center">
          {isUpdate ? "UPDATE SUPERMARKET" : "ADD SUPERMARKET"}
        </h4>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="col-122">
          <div className="card custom-card">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-12">
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Name: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="storeName"
                    placeholder="Super Market Name"
                    value={formik.values.storeName}
                    onChange={formik.handleChange}
                  />
                  {props.formik.errors.storeName &&
                  props.formik.touched.storeName ? (
                    <p className="text-start error">
                      {props.formik.errors.storeName}
                    </p>
                  ) : null}
                </Form.Group>

                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Coordinates: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="coordinates"
                    placeholder="Coordinates"
                    value={formik.values.coordinates}
                    onChange={handleCoordinateChange}
                  />
                  {props.formik.errors.coordinates &&
                  props.formik.touched.coordinates ? (
                    <p className="text-start error">
                      {props.formik.errors.coordinates}
                    </p>
                  ) : null}
                </Form.Group>

                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Image: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="storeImage"
                    // value={props.formik.values.Email}
                    onChange={handleImageChange}
                  />
                  {props.formik.errors.storeImage &&
                  props.formik.touched.storeImage ? (
                    <p className="text-start error">
                      {props.formik.errors.storeImage}
                    </p>
                  ) : null}
                </Form.Group>
                {previewImage && (
                  <div>
                    <img src={previewImage} alt="Preview" height={150} />
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "15px",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  {isUpdate ? "Update" : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddSuperMarketModel;
