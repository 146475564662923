import React, { useEffect } from "react";
import { Button, Card, Col, Pagination, Row } from "react-bootstrap";
import DeleteSuperMarketModel from "./DeleteSuperMarketModel";
import ProductEdit from "./ProductEdit";
import useDialogState from "../../../hooks/useDialogState";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CategoryService from "../../../services/categories.service";
import BrandService from "../../../services/brands.service";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import ProductService from "../../../services/product.service";
import withLoader from "../../../layout/loader/withLoader";
import Loader1 from "../../../../assets/img/svgs/loader.svg";

const CategoriesProduct = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [dropDownData, setDropDownData] = useState([]);
  const [dropDownData2, setDropDownData2] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [name, setName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const {
    open: show,
    handleClickOpen: handelDialogOpen,
    handleClickClose: handleDialogClose,
  } = useDialogState();
  const navigate = useNavigate();
  const { storeId } = useParams();
  const limit = 12;
  localStorage.setItem("SuperMarketId", storeId);
  const initialValue = {
    storeId: "",
    categoryId: "",
    brandId: "",
    productName: "",
    productImage: null,
    price: "",
    description: "",
    quantity: "",
  };
  const validationSchema = Yup.object().shape({
    // storeId: Yup.string().required('Please enter Store Id!'),
    categoryId: Yup.string().required("Please select a Category!"),
    brandId: Yup.string().required("Please select a Brand!"),
    productName: Yup.string().required("Please enter the Name!"),
    productImage: Yup.mixed().required("Please Select an Image!"),
    price: Yup.string().required("Please enter the Price!"),
    description: Yup.string().required("Please enter the Description!"),
    quantity: Yup.string()
      .matches(/^[0-9]/)
      .required("Please enter the Quantity!"),
  });
  const handleFormSubmit = async (values, action) => {
    if (!values._id) {
      try {
        const formData = new FormData();
        formData.append("storeId", storeId);
        formData.append("categoryId", values.categoryId);
        formData.append("brandId", values.brandId);
        formData.append("productName", values.productName);
        formData.append("productImage", values.productImage);
        formData.append("price", values.price);
        formData.append("description", values.description);
        formData.append("quantity", values.quantity);
        const response = await ProductService.CreateProduct(formData);
        console.log("product", response);
        action.resetForm();
        AllProducts();
        handleDialogClose();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        const formData = new FormData();
        formData.append("storeId", storeId);
        formData.append("categoryId", values.categoryId);
        formData.append("brandId", values.brandId);
        formData.append("productName", values.productName);
        formData.append("productImage", values.productImage);
        formData.append("price", values.price);
        formData.append("description", values.description);
        formData.append("quantity", values.quantity);
        const response = await ProductService.Update(values._id, formData);
        action.resetForm();
        AllProducts();
        handleDialogClose();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });
  const AllProducts = async () => {
    try {
      setIsLoading(true);
      const response = await ProductService.GetAllProducts(
        storeId,
        page,
        limit
      );
      setIsLoading(false);
      console.log("product", response.data.data);
      setProducts(response.data.data);
      setTotalRows(response.data.totalProduct);

      const totalPages = Math.ceil(response.data.totalProduct / limit);
      setTotalPages(totalPages);
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    AllProducts();
  }, [page]);

  const handleDelete = (id, productName) => {
    setShowDelete(true);
    setDeleteId(id);
    setName(productName);
  };
  const viewDetails = (id) => {
    navigate(`/supermarket/peoductdetails/${id}`);
  };
  const handleDialogOpen = async (id) => {
    handelDialogOpen();
    if (id) {
      setIsUpdate(true);
      const response = await ProductService.GetOne(id);
      const result = response.data.data;
      formik.setFieldValue("storeId", result.storeId);
      formik.setFieldValue("categoryId", result.categoryId);
      formik.setFieldValue("brandId", result.brandId);
      formik.setFieldValue("productName", result.productName);
      formik.setFieldValue("price", result.price);
      formik.setFieldValue("productImage", result.productImage);
      formik.setFieldValue("description", result.description);
      formik.setFieldValue("quantity", result.quantity);
      formik.setFieldValue("_id", result._id);
      setPreviewImage(result.productImage);
    } else {
      setIsUpdate(false);
      setPreviewImage(null);
      formik.resetForm();
    }
  };
  const nextPage = (page) => {
    setPage(page);
  };
  const previousPage = (page) => {
    setPage(page);
  };

  const pageSize = 12;

  const handleImageChange = (e) => {
    const imgFile = e.target.files[0];
    formik.setFieldValue("productImage", imgFile);
    console.log("Images", URL.createObjectURL(e.target.files[0]));
    setPreviewImage(URL.createObjectURL(imgFile));
  };
  const AllCategories = async () => {
    try {
      const response = await CategoryService.GetCategoryList();
      setDropDownData(response.data.data);
    } catch (e) {
      console.log("e", e);
    }
  };
  const AllBrands = async () => {
    try {
      const response = await BrandService.GetBrandList();
      setDropDownData2(response.data.data);
    } catch (e) {
      console.log("e", e);
    }
  };

  useEffect(() => {
    AllCategories();
    AllBrands();
  }, []);
  const CategoriesOptions = [
    ...dropDownData.map((item) => ({
      value: item._id,
      label: item.categoryName,
    })),
  ];
  const BrandsOptions = [
    ...dropDownData2.map((item) => ({
      value: item._id,
      label: item.brandName,
    })),
  ];
  const handleDialogCloseReset = () => {
    handleDialogClose();
    formik.resetForm();
  };

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Product</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => handleDialogOpen("")}
            >
              <i className="fe fe-plus me-2"></i>ADD
            </Button>
            {showDelete && (
              <DeleteSuperMarketModel
                show={showDelete}
                onHide={() => setShowDelete(false)}
                name={name}
                deleteId={deleteId}
                AllProducts={AllProducts}
              />
            )}
            {show && (
              <ProductEdit
                formik={formik}
                isUpdate={isUpdate}
                CategoriesOptions={CategoriesOptions}
                BrandsOptions={BrandsOptions}
                handleImageChange={handleImageChange}
                previewImage={previewImage}
                show={show}
                handelDialogOpen={handelDialogOpen}
                handleDialogClose={handleDialogCloseReset}
              />
            )}
          </div>
        </div>
      </div>
      <div lg={12} className="w-100">
        <Row className="row row-sm">
          {isLoading ? (
            <img src={Loader1} alt="Loader" height={70} />
          ) : (
            <>
              {products != "" ? (
                products.map((data, index) => (
                  <>
                    <Col
                      sm={6}
                      md={4}
                      xl={2}
                      xs={12}
                      className="mb-5"
                      key={index}
                    >
                      {/* <div> */}
                      <Card
                        className="custom-card mb-1"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          onClick={() => viewDetails(data._id)}
                          className="align-items-center"
                        >
                          <div className="d-flex justify-content-center">
                            <img
                              src={data.productImage}
                              height={150}
                              style={{
                                position: "relative",
                                objectFit: "cover",
                              }}
                              alt="brandimage"
                              className="brand-image"
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <div className="d-flex flex-column align-items-start mx-3">
                              <label className="tx-18 fw-bold m-0">
                                {data.productName}
                              </label>
                              <p className="m-0 tx-12">{data.item}</p>
                              <p
                                className="my-2 tx-22 fw-bold"
                                style={{ color: "#029E5E" }}
                              >
                                {data.price}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-end my-2">
                          <button
                            className="ms-auto btn btn-primary btn-sm me-1"
                            style={{ height: "28px" }}
                            onClick={() => handleDialogOpen(data._id)}
                          >
                            <i className="fe fe-edit"></i>
                          </button>
                          <div className="me-2 align-items-baseline">
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                handleDelete(data._id, data.productName)
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </Card>
                      {/* </div> */}
                    </Col>
                  </>
                ))
              ) : (
                <div className="text-center mt-3 mb-3">
                  <p>
                    No Products available. Click the "Add" button to create a
                    new Product.
                  </p>
                </div>
              )}
            </>
          )}
          {products != "" && (
            <Pagination
              className="pagination-circle mb-0 ms-lg-3"
              style={{ justifyContent: "center" }}
            >
              <Pagination.Item
                onClick={() => previousPage(page - 1)}
                disabled={page === 1}
              >
                <i className="fas fa-angle-double-left"></i>
              </Pagination.Item>
              {Array.from({ length: totalPages }).map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <Pagination.Item
                    onClick={() => nextPage(pageNumber)}
                    key={pageNumber}
                    active={pageNumber === page}
                  >
                    {pageNumber}
                  </Pagination.Item>
                );
              })}
              <Pagination.Item
                onClick={() => nextPage(page + 1)}
                disabled={page === totalPages}
              >
                <i className="fas fa-angle-double-right"></i>
              </Pagination.Item>
            </Pagination>
          )}
        </Row>
      </div>
    </>
  );
};

export default withLoader(CategoriesProduct);
