import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Searchable from "react-searchable-dropdown";
import { ROLE } from "../../../utils/Enum";
import Select from "react-select";

const AddUser = (props) => {
  const {
    show,
    handleDialogClose,
    BrandsOptions,
    userRole,
    handleSelectRoleChange,
    formik,
    id,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Modal
      {...props}
      show={show}
      onHide={handleDialogClose}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h4 className="mt-3">{id ? "UPDATE USER" : "ADD USER"}</h4>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="col-122">
          <div className="card custom-card">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-12">
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Email Address: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={props.formik.values.email}
                    onChange={props.formik.handleChange}
                  />
                  {props.formik.errors.email && props.formik.touched.email ? (
                    <p className="text-start error">
                      {props.formik.errors.email}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Password: <span className="tx-danger">*</span>
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        style={{
                          padding: "0.375rem 0.5rem",
                          border: "aliceblue",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <FaEyeSlash style={{ fontSize: "1rem" }} />
                        ) : (
                          <FaEye style={{ fontSize: "1rem" }} />
                        )}
                      </button>
                    </div>
                  </div>
                  {props.formik.errors.password &&
                  props.formik.touched.password ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.password}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormikRole"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Role : <span className="tx-danger">*</span>
                  </Form.Label>
                  <Searchable
                    value={props.formik.values.userType}
                    placeholder="Choose one"
                    notFoundText="No result found"
                    noInput
                    options={ROLE}
                    onSelect={
                      //   (value) => {
                      //   props.formik.setFieldValue("userType", value);
                      // }
                      handleSelectRoleChange
                    }
                    listMaxHeight={80}
                  />
                  {props.formik.errors.userType &&
                  props.formik.touched.userType ? (
                    <p className="text-start error">
                      {props.formik.errors.userType}
                    </p>
                  ) : null}
                </Form.Group>
                {userRole === "SuperMarketUser" && (
                  <Form.Group
                    controlId="validationFormik101"
                    className="position-relative"
                  >
                    <Form.Label
                      style={{
                        textAlign: "start",
                        color: "#000",
                        marginTop: "15px",
                      }}
                    >
                      SuperMarket: <span className="tx-danger">*</span>
                    </Form.Label>
                    <Select
                      style={{ width: "100%" }}
                      value={BrandsOptions.find(
                        (res) => res.value === props.formik.values.storeId
                      )}
                      placeholder="Choose one"
                      options={BrandsOptions}
                      onChange={(selected) => {
                        props.formik.setFieldValue("storeId", selected.value);
                      }}
                    />
                    {/* {props.formik.errors.categoryId &&
                                        props.formik.touched.categoryId ? (
                                        <p className="text-start error">
                                            {props.formik.errors.categoryId}
                                        </p>
                                    ) : null} */}
                  </Form.Group>
                )}
              </div>
              <Form.Group className="position-relative col-12"></Form.Group>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "15px",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  {id ? "Update" : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUser;
