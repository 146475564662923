import React from "react";
import "../src/assets/css/style.css";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setToken } from "./app/store/slice/auth";
// import { getFirebaseToken, onForegroundMessage } from "./firebase";
import { toast } from "react-toastify";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(setToken(localStorage.getItem('token')));
    }
    if (localStorage.getItem('isLoggedIn')) {
      dispatch(setLoggedIn(JSON.parse(localStorage.getItem('isLoggedIn'))));
    }
  }, []);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const isLoggedin = isLoggedIn || !!localStorage.getItem('token');
  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );
  // useEffect(() => {
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       localStorage.setItem("fcmToken", firebaseToken)
  //       console.log("Firebase token: ", firebaseToken);
  //       // if (firebaseToken) {
  //       //   setShowNotificationBanner(false);
  //       // }
  //     })
  //     .catch((err) =>
  //       console.error("An error occured while retrieving firebase token. ", err)
  //     );
  //   onForegroundMessage()
  //     .then((payload) => {
  //       console.log("Received foreground message: ", payload);
  //       const {
  //         notification: { title, body },
  //       } = payload;
  //       toast(<ToastifyNotification title={title} body={body} />);
  //     })
  //     .catch((err) =>
  //       console.log(
  //         "An error occured while retrieving foreground message. ",
  //         err
  //       )
  //     );
  // }, [])


  return (
    <div className="App">
      <BrowserRouter basename={process.env.REACT_APP_HOME_PAGE_URL}>
        <React.Suspense>
          <Routes isLoggedIn={isLoggedin} />
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
}
export default App;
