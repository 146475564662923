import serverCall from '../../serverCall'

const CreateCategory = async (data) => {
    try {

        const response = await serverCall.post("/category/admin/create-category", data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const GetCategoryList = async (data) => {
    try {
        const response = await serverCall.get("/category/get-all-category", data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const GetAllCategory = async (page, limit, data) => {
    try {
        const response = await serverCall.get(`/category/get-all-category?page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const GetOne = async (id) => {
    try {
        const response = await serverCall.get(`/category/get-category/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const Update = async (id, data) => {
    try {
        const response = await serverCall.patch(`/category/admin/update-category/${id}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const Delete = async (id) => {
    try {
        const response = await serverCall.delete(`/category/admin/delete-category/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const CategoryService = {
    CreateCategory,
    GetAllCategory,
    GetCategoryList,
    GetOne,
    Update,
    Delete
};
export default CategoryService;