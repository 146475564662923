import serverCall from '../../serverCall'

const GetAllSales = async (page, limit, data) => {
    try {
        const response = await serverCall.get(`/cart/admin/get-accepted-orders?page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}

const SalesService = {
    GetAllSales,
};
export default SalesService;