import React, { useState, useEffect } from 'react';
import { Breadcrumb, Button, Card, Col, Pagination, Row, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import ReceiptModel from './ReceiptModel';
import OrderService from '../../../services/order.service';
import withLoader from '../../../layout/loader/withLoader';
import Loader1 from '../../../../assets/img/svgs/loader.svg';


//get all order page 
const MainOrder = () => {
    const [showDelete, setShowDelete] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1);

    const limit = 10;

    const handlePageChange = (page) => {
        setPage(page);
    };

    const AllOrders = async () => {
        try {
            setLoading(true);
            const response = await OrderService.GetAllOrders(page, limit);
            setLoading(false);
            console.log("Response", response.data.data);
            setOrderData(response.data.data);
            setTotalRows(response.data.totalOrders);
        } catch (e) {
            setLoading(false);
            console.log("e hrsdd", e);
        }
    };

    useEffect(() => {
        AllOrders();
        window.scrollTo(0, 0);
    }, [page]);

    return (
        <>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">ORDERS</h2>
                </div>
            </div>
            <div lg={12} className="w-100">
                {showDelete && <ReceiptModel show={showDelete} onHide={() => setShowDelete(false)} />}
                <Card className="custom-card overflow-hidden">
                    <Card.Body>
                        <div className="table-responsive">
                            <Table bordered className="text-nowrap table-shopping-cart mb-0">
                                <thead className="text-muted">
                                    <tr className="small text-uppercase">
                                        <th scope="col">ID</th>
                                        <th scope="col">User Name</th>
                                        <th scope="col">Super Markets</th>
                                        <th scope="col" className="wd-120">
                                            Items
                                        </th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Token</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" className="text-center ">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (

                                        <div className="loader-overlay">
                                            <img src={Loader1} alt="Loader" height={70} className="text-center" style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', marginTop: "30px" }} />
                                        </div>

                                    )
                                        : orderData.length === 0 ? (
                                            <tr>
                                                <td colSpan="8" className="text-center">No Any Order available</td>
                                            </tr>
                                        ) : (
                                            <>
                                                {orderData && orderData.map((list, index) => (
                                                    <tr key={index} data-index={index}>
                                                        <td>{list._id}</td>
                                                        <td>{list.userId?.userName}</td>
                                                        <td>
                                                            <div className="media">
                                                                <div className="card-aside-img">
                                                                    <img
                                                                        src={list.storeId?.storeImage}
                                                                        alt="img"
                                                                        className="img-sm"
                                                                    />
                                                                </div>
                                                                <div className="media-body my-auto">
                                                                    <div className="card-item-desc mt-0">
                                                                        <h6 className="font-weight-semibold mt-0 text-uppercase">
                                                                            {list.storeId?.storeName}
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{list.productCount}</td>
                                                        <td>{list.orderDate}</td>
                                                        <td>{list.token}</td>
                                                        <td>
                                                            <button
                                                                className={`btn btn-sm btn-${list.orderStatus === "Accept" ? "success" : list.orderStatus === "Pending" ? "warning" : "danger"} text-white`}
                                                                style={{
                                                                    width: "110px",
                                                                    textAlign: "center",
                                                                    cursor: "default",
                                                                }}
                                                            >
                                                                <i className={`fa fa-${list.orderStatus === "Accept" ? "check" : list.orderStatus === "Pending" ? "clock" : "close"}`}></i> {list.orderStatus}
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <Link to={`/order/details/${list._id}/${list.userId?.userName}`} state={{
                                                                Id: list._id,
                                                                Name: list.userId?.userName
                                                            }} className='btn btn-primary btn-sm' >
                                                                <i className='fas fa-eye'></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                </tbody>




                            </Table>
                        </div>
                        {orderData.length > 0 && (
                            <Pagination className="mt-3 justify-content-end">
                                <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} />
                                {Array.from({ length: Math.ceil(totalRows / limit) }).map((_, index) => (
                                    <Pagination.Item key={index + 1} active={index + 1 === page} onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === Math.ceil(totalRows / limit)} />
                            </Pagination>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default withLoader(MainOrder);
