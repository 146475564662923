import serverCall from '../../serverCall'

const AllNotifications = async (page, limit, data) => {
    try {
        const response = await serverCall.get(`/notification/get-all-notification?page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const UnseenNotifications = async (page, limit, data) => {
    try {
        const response = await serverCall.get(`/notification/get-unseen-notification?page=1&limit=10`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const SeenNotifications = async (Id) => {
    try {
        const response = await serverCall.post(`/notification/seen-notification?notificationId=${Id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const NotificationsService = {
    AllNotifications,
    UnseenNotifications,
    SeenNotifications
};
export default NotificationsService;