let MENUITEMS = [];
const Email = localStorage.getItem("email");
console.log('Email', Email)
if (Email === "admin@gmail.com") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/dashboard`,
          icon: "ti-home",
          type: "link",
          active: false,
          selected: false,
          title: "Dashboard",
        },
        {
          path: `/users`,
          icon: "ti-user",
          type: "link",
          active: false,
          selected: false,
          title: "Users",
        },
        {
          path: `/supermarket`,
          icon: "ti-shopping-cart",
          type: "link",
          active: false,
          selected: false,
          title: "Super Market",
        },
        {
          path: `/categories`,
          icon: "fas fa-layer-group",
          type: "link",
          active: false,
          selected: false,
          title: "Categories",
        }, {
          path: `/brands`,
          icon: "fa fa-brands fa-bandcamp",
          type: "link",
          active: false,
          selected: false,
          title: "Brands",
        },
        {
          path: `/order`,
          icon: "ti-server",
          type: "link",
          active: false,
          selected: false,
          title: "Orders",
        },
        {
          path: `/sales`,
          icon: "ti-money",
          type: "link",
          active: false,
          selected: false,
          title: "Sales",
        },
        {
          path: `/notifications`,
          icon: "fa fa-bell",
          type: "link",
          active: false,
          selected: false,
          title: "Notifications",
        },
      ],
    }
  ]
} else if (Email === "supermarketuser@gmail.com") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/store/dashboard`,
          icon: "ti-home",
          type: "link",
          active: false,
          selected: false,
          title: "Dashboard",
        },
        {
          path: `/store/categories`,
          icon: "ti-panel",
          type: "link",
          active: false,
          selected: false,
          title: "Categories",
        },
        {
          path: `/store/brands`,
          icon: "si si-badge",
          type: "link",
          active: false,
          selected: false,
          title: "Brands",
        },
        {
          path: `/store/products`,
          icon: "ti-map",
          type: "link",
          active: false,
          selected: false,
          title: "Products",
        },
        {
          path: `/store/orders`,
          icon: "ti-server",
          type: "link",
          active: false,
          selected: false,
          title: "Orders",
        },
        {
          path: `/store/sales`,
          icon: "ti-money",
          type: "link",
          active: false,
          selected: false,
          title: "Sales",
        },
        {
          path: `/store/notifications`,
          icon: "fa fa-bell",
          type: "link",
          active: false,
          selected: false,
          title: "Notification",
        },
      ],
    },
  ];
} else if (Email === "teammember@gmail.com") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/team/orders`,
          icon: "ti-server",
          type: "link",
          active: false,
          selected: false,
          title: "Orders"
        }
      ]
    }
  ]
}

export { MENUITEMS };
