import serverCall from '../../serverCall'

const GetAllOrders = async (page, limit) => {
    try {
        const response = await serverCall.get(`/cart/admin/all-order?page=${page}&limit=${limit}`);
        return response
    } catch (e) {
        console.log("ehrsd", e);
    }
}
const OrderNotification = async (id) => {
    try {
        const response = await serverCall.get(`/cart/admin/get-order-detail/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}

// const AcceptOrder = async (page, limit) => {
//     try {
//         const response = await serverCall.get(`/cart/admin/get-accepted-orders?page=${page}&limit=${limit}`);
//         return response
//     } catch (e) {
//         console.log("e", e);
//     }
// }

const OrderDetails = async (id,data) => {
    try {
        const response = await serverCall.get(`/cart/admin/get-order-detail/${id}`,data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}

const StatusUpdate = async (data) => {
    try {
        const response = await serverCall.post('/wallet/confirmation-from-admin', data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const OrderService = {
    GetAllOrders,
    OrderDetails,
    StatusUpdate,
    OrderNotification,
    // AcceptOrder
};
export default OrderService;