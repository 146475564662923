import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Multiselect from "react-select";
import withLoader from "../../../layout/loader/withLoader";
import MainDashboard from "./MainDashboard";
import { useNavigate } from "react-router-dom";
import DashboardService from "../../../services/dashboard.service";
import Loader1 from '../../../../assets/img/svgs/loader.svg';

function Dashboard() {
    const [activeOrders, setActiveOrders] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [activeStores, setActiveStores] = useState([]);
    const [activeProducts, setActiveProducts] = useState([]); //hrsd chages
    const [users, setUsers] = useState('')
    const [orders, setOrders] = useState('')
    const [products, setProducts] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const [stores, setStores] = useState('')
    const navigate = useNavigate()
    const DataGraph = async () => {
        try {
            const response = await DashboardService.GraphData();
            const result = response.data;
            setUsers(result.TotalUsers);
            setStores(result.ToatalStore);
            setOrders(result.TotalOrder);
            setProducts(result.TotalProduct);
            setActiveUsers(result.user);
            setActiveOrders(result.order);
            setActiveStores(result.store);
            console.log("ssssssssss", result);
            setActiveProducts(result.product);  //hrsd changes
            console.log("dashboard", response.data);
        } catch (e) {
            console.log("e", e);
        }
        finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        DataGraph()
        window.scrollTo(0, 0);
    }, [])
    console.log(setActiveOrders);
    return (
        <Fragment>
            <div className="page-header">
                <h2 className="main-content-title tx-24 mb-4 mt-2">
                    DASHBOARD
                </h2>
            </div>
            <Row>
                <div className="row mb-4 summary_cards">
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card pointer" onClick={() => navigate('/users/')}>
                            <Card.Body>
                                <div className="card-order">
                                    <label className="main-content-label font-weight-bold mb-3 pt-1">
                                        Users
                                    </label>
                                    {isLoading ? (
                                        <img src={Loader1} alt="Loader" height={50} />
                                    ) : (
                                        <h2 className="text-end card-item-icon card-icon">
                                            <i className="mdi mdi-account-multiple icon-size float-start text-primary"></i>
                                            <span className="font-weight-bold">{users}</span>
                                        </h2>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card pointer" onClick={() => navigate('/supermarket/')}>
                            <Card.Body>
                                <div className="card-order ">
                                    <label className="main-content-label font-weight-bold mb-3 pt-1">Super Markets
                                    </label>
                                    {isLoading ? (
                                        <img src={Loader1} alt="Loader" height={50} />
                                    ) : (
                                        <h2 className="text-end card-item-icon card-icon">
                                            <i className="mdi mdi-account-multiple icon-size float-start text-primary"></i>
                                            <span className="font-weight-bold">{stores}</span>
                                        </h2>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card pointer" onClick={() => navigate('/order/')}>
                            <Card.Body>
                                <div className="card-order ">
                                    <label className="main-content-label font-weight-bold mb-3 pt-1">
                                        {("orders")}
                                    </label>
                                    {isLoading ? (
                                        <img src={Loader1} alt="Loader" height={50} />
                                    ) : (
                                        <h2 className="text-end card-item-icon card-icon">
                                            <i className="mdi mdi-account-multiple icon-size float-start text-primary"></i>
                                            <span className="font-weight-bold">{orders}</span>
                                        </h2>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card">
                            <Card.Body>
                                <div className="card-order ">
                                    <label className="main-content-label font-weight-bold mb-3 pt-1">
                                        Products
                                    </label>
                                    {isLoading ? (
                                        <img src={Loader1} alt="Loader" height={50} />
                                    ) : (
                                        <h2 className="text-end card-item-icon card-icon">
                                            <i className="mdi mdi-account-multiple icon-size float-start text-primary"></i>
                                            <span className="font-weight-bold">{products}</span>
                                        </h2>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>
            </Row>
            <Row className="row-sm">
                <Col sm={12} lg={12} xl={12}>
                    <Card className="custom-card overflow-hidden">
                        <Card.Header className="border-bottom-0">
                            {/* <div> */}
                            <label className="main-content-label mb-2">
                                GRAPH
                            </label>
                            {/* </div> */}
                        </Card.Header>
                        <Card.Body className="ps-12">
                            <div>
                                <div className="chart-dropshadow2 ht-300">
                                    <div className="chart-dropshadow2 ht-300">
                                        <MainDashboard
                                            activeStores={activeStores}
                                            activeUsers={activeUsers}
                                            activeOrders={activeOrders}
                                            activeProducts={activeProducts}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

// Dashboard.propTypes = {};

// Dashboard.defaultProps = {};

export default withLoader(Dashboard);
