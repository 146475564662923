import React from 'react'
import { Button, Modal } from 'react-bootstrap';


const ReceiptModel = (props) => {
    const { imageCapture } = props
    console.log("imageCaptureHrsd", imageCapture);
    return (
        <>
            <Modal
                {...props}
                // show={showDelete}
                onHide={props.onHide}
                size="large"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton >
                    <Modal.Title> <p className='fs-25 mb-0'>Image</p></Modal.Title></Modal.Header>
                <Modal.Body className="text-center">
                    <div className="d-flex flex-wrap">
                        <img
                            src={imageCapture}
                            alt={`Image`}
                            className="img-thumbnail m-2"
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReceiptModel
