import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';

const BrandEditModel = (props) => {
    const { formik, show, handleDialogClose, isUpdate, handleImageChange, previewImage } = props
    return (
        <Modal
            {...props}
            show={show}
            onHide={handleDialogClose}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <h4 className="mt-3 align-items-center">{isUpdate ? "UPDATE" : "ADD"}</h4>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="col-122">
                    <div className="card custom-card">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-12">
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Name: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="brandName"
                                        placeholder="Brand Name"
                                        value={formik.values.brandName}
                                        onChange={formik.handleChange}
                                    />
                                    {props.formik.errors.brandName &&
                                        props.formik.touched.brandName ? (
                                        <p className="text-start error">
                                            {props.formik.errors.brandName}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                ><Form.Label
                                    style={{
                                        textAlign: "start",
                                        color: "#000",
                                        marginTop: "15px",
                                    }}
                                >
                                        Image: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="brandImage"
                                        // value={props.formik.values.brandImage}
                                        onChange={handleImageChange}
                                    />
                                    {props.formik.errors.brandImage &&
                                        props.formik.touched.brandImage ? (
                                        <p className="text-start error">
                                            {props.formik.errors.brandImage}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                {previewImage && (
                                    <div>
                                        {/* <p>Preview:</p> */}
                                        <img src={previewImage} alt="Preview" height={150} />
                                    </div>
                                )}
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <Button
                                    type="submit"
                                    style={{
                                        display: "flex",
                                        marginLeft: "auto",
                                        marginRight: "15px",
                                        marginTop: "20px",
                                        textAlign: 'center',
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
}

export default BrandEditModel
