import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import CategoryService from '../../../services/categories.service';
import { enqueueSnackbar } from 'notistack';

const DeleteModal = (props) => {
    const { catName, deleteId, onHide, AllCategories } = props
    const CategoryDelete = async () => {
        const response = await CategoryService.Delete(deleteId);
        onHide();
        AllCategories();
        enqueueSnackbar("Deleted User", {
            variant: "success", anchorOrigin: {
                vertical: "bottom", horizontal: "right"
            }
        });
    }
    return (
        <>
            <Modal
                {...props}
                // show={showDelete}
                onHide={props.onHide}
                size="large"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

                    <h4 className="tx-danger tx-semibold mg-b-20">Delete?</h4>
                    <p className="mg-b-20 mg-x-20 ">
                        Are you sure want to delete <b>{catName}</b> ?
                    </p>

                    <Button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            CategoryDelete();
                        }}
                        variant="danger"
                    >
                        Delete
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DeleteModal
