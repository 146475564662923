import { Select } from 'antd';
import React, { Fragment, useRef, useState } from 'react'
import { Button, Card, Col, Form, FormGroup, Pagination, Row, Table } from 'react-bootstrap'
import ReceiptModel from '../orders/ReceiptModel';
import reward from '../../../assets/img/reward.png';
import Market from '../../../assets/img/image 17 (3).svg';
import brand from '../../../assets/img/brand.png';
import eco from '../../../assets/img/eco.png';
import success from '../../../assets/img/success.png';
import organic from '../../../assets/img/organic.png';
import best from '../../../assets/img/best.png';
import family from '../../../assets/img/family.png';
import grocery from '../../../assets/img/grocery.png';
import logoFood3 from '../../../assets/img/image 14.svg';
import vegan from '../../../assets/img/vegan.png';
import cart from '../../../assets/img/cart.png';
import no1 from '../../../assets/img/no1.png';
import { Link, useParams } from 'react-router-dom';
import UserService from '../../../services/user.service';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import useDialogState from '../../../hooks/useDialogState';
import { enqueueSnackbar } from 'notistack';
import SuperMarketService from '../../../services/supermarket.service';
import AddSuperMarketModel from '../superMarket/AddSuperMarketModel';
import AddQrCodeModel from './AddQrCodeModal';
import moment from 'moment';
import QrCodeImageModal from './QrCodeImageModal';


const UserDetails = () => {
    const [showAddQrCodeModal, setShowAddQrCodeModal] = useState(false);
    const [showQrCodeImageModal, setShowQrCodeImageModal] = useState(false);
    const [showReceipt, setShowReceipt] = useState(false);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userToken, setUserToken] = useState('');
    const [userMobileNo, setUserMobileNo] = useState('');
    const [orderData, setOrderData] = useState([]);
    const [qrCodeData, setQrCodeData] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const { open: show, handleClickOpen: handelDialogOpen, handleClickClose: handleDialogClose } = useDialogState()
    const [qrCodeName, setQrCodeName] = useState('');
    const [qrCodeImage, setQrCodeImage] = useState('');
    // const { open: showQrCodeModal, handleClickOpen: handleQrCodeModalOpen, handleClickClose: handleQrCodeModalClose } = useDialogState();

    const handleQrCodeButtonClick = (qrCodeImage, qrCodeName) => {
        setQrCodeImage(qrCodeImage);
        setShowQrCodeImageModal(true);
        setQrCodeName(qrCodeName)
    };

    const initialValue = {
        token: '',
    }
    const validationSchema = Yup.object().shape({
        token: Yup.number().required('Please Enter Token!'),
    })
    const { Id } = useParams();
    // const name = FirstName
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const handlePageChange = async (newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const GetQrCodeUser = async () => {
        try {
            // setIsLoading(true);
            const response = await UserService.UserGetQrCode(Id);

            const result = response.data.data;
            setUserName(result.userName)
            setUserToken(result.userToken)
            console.log("ResponseDetails", response.data.data);
            const Orderdata = response.data.data
            setQrCodeData(Orderdata);
            setTotalRows(response.data.data.totalOrders);

        } catch (e) {
            console.log("e", e);
            // setIsLoading(false);
        }
    };

    useEffect(() => {
        GetQrCodeUser();
    }, [])


    const GetUserDetails = async () => {
        try {
            // setIsLoading(true);
            const response = await UserService.OneUserDetails(Id, pageNumber, dataPerPage);
            const result = response.data.data.userDetail;
            setUserName(result.userName)
            setUserEmail(result.email)
            setUserToken(result.userToken)
            setUserMobileNo(result.mobile);
            console.log("ResponseDetails", response.data.data);
            const Orderdata = response.data.data
            setOrderData(Orderdata.ordersWithProductCount);
            setTotalRows(response.data.data.totalOrders);

        } catch (e) {
            console.log("e", e);
            // setIsLoading(false);
        }
    };

    useEffect(() => {
        GetUserDetails();
    }, [pageNumber]);

    const handleFormSubmit = async (values, action) => {
        if (!values._id) {
            const response = await UserService.UserQrCode(Id, values.token);
            action.resetForm();
            setShowAddQrCodeModal(false);
            const updatedData = response.data.data;
            setOrderData(updatedData.ordersWithProductCount);
            setTotalRows(updatedData.totalOrders);
            enqueueSnackbar(response.data.message, {
                variant: "success", anchorOrigin: {
                    vertical: "bottom", horizontal: "right"
                }
            })
        } else {
            enqueueSnackbar({
                variant: "error", anchorOrigin: {
                    vertical: "bottom", horizontal: "right"
                }
            });
        }
        GetQrCodeUser();
        GetUserDetails();
    }

    const handleDialogOpen = async (token) => {
        handelDialogOpen()
        formik.setFieldValue("token", token);

    }

    const handleDialogCloseReset = () => {
        handleDialogClose()
        formik.resetForm();
    }

    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: validationSchema,
        onSubmit: handleFormSubmit
    })

    return (

        <Fragment>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">USERS DETAILS</h2>
                </div>

            </div>
            <Row>
                <div className="row mb-4 summary_cards">
                    {/******************MAIN NAME-EMAIL COMPONENT*****************/}
                    <Col sm={12} md={6} lg={6} xl={5}>
                        <Card className="custom-card">
                            <Card.Body>
                                <div className="row">
                                    <FormGroup className="form-group">
                                        <Row className=" row-sm">
                                            <Col md={4} className="d-flex align-items-center">
                                                <Form.Label className="text-dark">
                                                    Name:
                                                </Form.Label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control

                                                    type="text"
                                                    name="username"
                                                    value={userName ? userName : "-"}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <Row className=" row-sm">
                                            <Col md={4} className="d-flex align-items-center">
                                                <Form.Label className="text-dark">
                                                    Email :
                                                </Form.Label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control

                                                    type="text"
                                                    name="adminEmail"
                                                    value={userEmail ? userEmail : "-"}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <Row className=" row-sm">
                                            <Col md={4} className="d-flex align-items-center">
                                                <Form.Label className="text-dark">
                                                    Phone No:
                                                </Form.Label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    type="text"
                                                    name="adminEmail"
                                                    value={userMobileNo ? userMobileNo : "-"}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/******************REWARD COMPONENT*****************/}
                    <Col sm={12} md={6} lg={3} xl={3}>
                        <Card className="custom-card mb-1">
                            <div
                                className='align-items-center'>
                                <div className='d-flex justify-content-center'>
                                    <img
                                        src={reward}
                                        height={170}
                                        style={{
                                            position: "relative",
                                            objectFit: "cover",
                                        }}
                                        alt="brandimage"
                                        className="brand-image"
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <h4 className='text-dark mx-2'>Rewards:</h4> <span><h2>{userToken}</h2></span>
                                </div>
                            </div>
                        </Card>

                    </Col>
                </div>
            </Row>
            {showReceipt && <ReceiptModel show={showReceipt} onHide={() => setShowReceipt(false)} />}
            {/* <div className="page-header"> */}
            {/******************ORDER Table*****************/}
            <h2 className="main-content-title tx-24 mb-4 mt-2">
                ORDERS
            </h2>
            {/* </div> */}
            <Row className="row-sm">
                <Col >
                    <Card className="custom-card overflow-hidden">
                        <Card.Body>
                            <div className="responsive">
                                <Table bordered className="text-nowrap table-shopping-cart mb-0">
                                    <thead className="text-muted">
                                        <tr className="small text-uppercase">
                                            <th scope="col">ID</th>
                                            <th scope="col">Super Markets</th>
                                            <th scope="col" className="wd-120">
                                                Items
                                            </th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Tokens</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" className="text-center ">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {orderData && orderData.length > 0 ? (
                                            orderData.map((list, index) => (
                                                <tr key={index} data-index={index}>
                                                    <td>{list._id}</td>
                                                    <td>
                                                        <div className="media">
                                                            <div className="card-aside-img">
                                                                <img
                                                                    src={list?.storeId ? list?.storeId?.storeImage : ''}
                                                                    alt="img"
                                                                    className="img-sm"
                                                                />
                                                            </div>
                                                            <div className="media-body my-auto">
                                                                <div className="card-item-desc mt-0">
                                                                    <h6 className="font-weight-semibold mt-0 text-uppercase">
                                                                        {list?.storeId?.storeName}
                                                                    </h6>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{list?.productCount}</td>
                                                    <td>{list?.orderDate}
                                                    </td>
                                                    <td>{list?.token}</td>
                                                    <td>
                                                        <button
                                                            className={`btn btn-sm btn-${list?.orderStatus === "Accept" ? "success" : list?.orderStatus === "Pending" ? "warning" : "danger"} text-white`}
                                                            style={{
                                                                width: "110px",
                                                                textAlign: "center",
                                                                cursor: "default",
                                                            }}
                                                        >
                                                            <i class={`fa fa-${list?.orderStatus === "Accept" ? "check" : list?.orderStatus === "Pending" ? "clock" : "close"}`}></i> {list?.orderStatus}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <Link to={`/users/${list?._id}`} state={{
                                                            Id: list?._id,
                                                            Name: userName
                                                        }} className='btn btn-primary btn-sm' >
                                                            <i className='fas fa-eye'></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6">No order data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            <Pagination className="mt-3 justify-content-end">
                                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                                {Array.from({ length: Math.ceil(totalRows / itemsPerPage) }).map((_, index) => (
                                    <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(totalRows / itemsPerPage)} />
                            </Pagination>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/******************QR CODE Table*****************/}
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mb-4 mt-2">
                        QR CODE Details
                    </h2>
                </div>
                <div className="d-flex align-items-center">
                    <div className="">
                        <Button
                            size="md"
                            variant="primary"
                            type="button"
                            className="my-2 btn-icon-text"
                            onClick={() => setShowAddQrCodeModal(true)}
                        >
                            <i className="fe fe-user-plus me-2"></i> Add Qr Code
                        </Button>
                        {showAddQrCodeModal && (
                            <AddQrCodeModel
                                formik={formik}
                                show={showAddQrCodeModal}
                                handelDialogOpen={() => setShowAddQrCodeModal(true)}
                                handleDialogClose={() => setShowAddQrCodeModal(false)}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Row className="row-sm">
                <Col >
                    <Card className="custom-card overflow-hidden">
                        <Card.Body>
                            <div className="responsive">
                                <Table bordered className="text-nowrap table-shopping-cart mb-0">
                                    <thead style={{ textAlign: "center" }} className="text-muted">
                                        <tr className="small text-uppercase">
                                            <th scope="col">ID</th>
                                            <th scope="col">Token</th>
                                            <th scope="col">Date</th>
                                            {/* <th scope="col">Tokens</th> */}
                                            <th scope="col">Status</th>
                                            <th scope="col">Image</th>
                                            <th scope="col" className="text-center ">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>

                                        {qrCodeData && qrCodeData.length > 0 ? (

                                            qrCodeData.map((list, index) => (
                                                <tr key={index} data-index={index}>
                                                    <td>{list._id}</td>
                                                    <td>{list?.token}</td>
                                                    <td>{moment(list?.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td><b>{list?.isUsed ? "Usable" : "Not Usable"}</b></td>

                                                    <td>
                                                        <div style={{ justifyContent: "center" }} className="media">
                                                            <div className="card-aside-img">
                                                                <img
                                                                    src={list?.qrCode}
                                                                    alt="img"
                                                                    className="img-sm"

                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            className="my-2 btn-icon-text"
                                                            onClick={() => handleQrCodeButtonClick(list?.qrCode, list?.token)}
                                                        >
                                                            <i className="fa fa-qrcode me-2"></i> Qr Code
                                                        </Button>

                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6">No QR Code data available</td>
                                            </tr>
                                        )

                                        }
                                    </tbody>
                                </Table>
                                {showQrCodeImageModal && (
                                    <QrCodeImageModal
                                        show={showQrCodeImageModal}
                                        handleDialogClose={() => setShowQrCodeImageModal(false)}
                                        qrCodeImage={qrCodeImage}
                                        qrCodeName={qrCodeName}
                                    />
                                )}

                            </div>
                            <Pagination className="mt-3 justify-content-end">
                                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                                {Array.from({ length: Math.ceil(totalRows / itemsPerPage) }).map((_, index) => (
                                    <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(totalRows / itemsPerPage)} />
                            </Pagination>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Fragment >
    )
}

export default UserDetails
