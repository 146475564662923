export const ROLE = [
  {
    value: "User",
    label: "User",
  },
  {
    value: "TeamMember",
    label: "Team Member",
  },
  {
    value: "SuperMarketUser",
    label: "Super Market User",
  },
  {
    value: "Cashier",
    label: "Cashier",
  },
];
