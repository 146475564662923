import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
// import { useTranslation } from "react-i18next";
ChartJS.register(...registerables);
//hrsd active product changes
const MainDashboard = ({ activeUsers, activeOrders,activeProducts }) => {
    // Linechart
    const linechart = {
        labels: [
            ("jan"),
            ("feb"),
            ("mar"),
            ("apr"),
            ("may"),
            ("jun"),
            ("jul"),
            ("aug"),
            ("sep"),
            ("oct"),
            ("nov"),
            ("dec"),
        ],
        datasets: [
            {
                label: ("Total Users"),
                data: [activeUsers?.January, activeUsers.February, activeUsers.March, activeUsers.April, activeUsers.May, activeUsers.June, activeUsers.July, activeUsers.August, activeUsers.September, activeUsers.October, activeUsers.November, activeUsers.December],
                // data: [0, 0, 20, 95, 15, 12, 12, 5, 4, 60, 0, 0],
                borderWidth: 3,
                backgroundColor: "transparent",
                borderColor: "#6259ca",
                pointBackgroundColor: "#ffffff",
                pointRadius: 0,
                type: "line",
                tension: 0.3,
            },
            {
                label: ("Total Orders"),
                data: [activeOrders?.January, activeOrders.February, activeOrders.March, activeOrders.April, activeOrders.May, activeOrders.June, activeOrders.July, activeOrders.August, activeOrders.September, activeOrders.October, activeOrders.November, activeOrders.December],
                // data: [0, 0, 42, 84, 10, 12, 12, 5, 0, 0, 0, 0],
                borderWidth: 3,
                backgroundColor: "transparent",
                borderColor: "rgb(75, 192, 192)",
                pointBackgroundColor: "#ffffff",
                pointRadius: 0,
                type: "line",
                tension: 0.3,
            },
            {
                label: ("Total Products"),
                data: [activeProducts?.January, activeProducts?.February, activeProducts?.March, activeProducts?.April, activeProducts?.May, activeProducts?.June, activeProducts?.July, activeProducts?.August, activeProducts?.September, activeProducts?.October, activeProducts?.November, activeProducts?.December],
                // data: [0, 0, 42, 84, 10, 12, 12, 5, 0, 0, 0, 0],
                borderWidth: 3,
                backgroundColor: "transparent",
                borderColor: "rgb(75, 192, 192)",
                pointBackgroundColor: "#ffffff",
                pointRadius: 0,
                type: "line",
                tension: 0.3,
            },
        ],
    };

    const linechartoptions = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            title: {
                display: true,
            },
            legend: {
                position: "top",
                display: true,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                ticks: {
                    fontColor: "#c8ccdb",
                },
                barPercentage: 0.7,
                display: true,
                grid: {
                    borderColor: "rgba(119, 119, 142, 0.2)",
                },
            },
            y: {
                beginAtZero: true,
                display: true,
                grid: {
                    borderColor: "rgba(119, 119, 142, 0.2)",
                },
                scaleLabel: {
                    display: true,
                    labelString: "Thousands",
                    fontColor: "transparent",
                },
            },
        },
        interaction: {
            intersect: false,
        },
    };

    return (
        <Line
            options={linechartoptions}
            data={linechart}
            className="barchart chart-dropshadow2 ht-300 chartjs-render-monitor"
            height="100"
        />
    );
};

export default MainDashboard;

