import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';

const AddQrCodeModel = (props) => {
    const { formik, show, handleDialogClose, isUpdate, handleImageChange, previewImage } = props
    return (
        <Modal
            {...props}
            show={show}
            onHide={handleDialogClose}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <h4 className="mt-3 align-items-center">{isUpdate ? "UPDATE Qr Code" : "Add Qr Code"}</h4>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="col-122">
                    <div className="card custom-card">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-12">
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Token: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="token"
                                        placeholder="Enter Token Number"
                                        value={formik.values.token}
                                        onChange={formik.handleChange}
                                    />
                                    {props.formik.errors.storeName &&
                                        props.formik.touched.storeName ? (
                                        <p className="text-start error">
                                            {props.formik.errors.token}
                                        </p>
                                    ) : null}
                                </Form.Group>
                            
                               
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <Button
                                    type="submit"
                                    style={{
                                        display: "flex",
                                        marginLeft: "auto",
                                        marginRight: "15px",
                                        marginTop: "20px",
                                        textAlign: 'center',
                                    }}
                                >
                                    {isUpdate ? 'Update' : 'Submit'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
        
    );
}

export default AddQrCodeModel;
