import { CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import NotificationsService from '../../../services/notifications.service';
import { useEffect } from 'react';

const Notifications = () => {
    const [data, setData] = useState([]);
    const [id, setId] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [emailUse, setEmailUse] = useState('');
    const [searchRecord, setSearchRecord] = useState("");
    const [searchValue, setsearchValue] = useState("");
    const [totalRows, setTotalRows] = useState();
    const [dataPerPage, setDataPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState();

    const handlePageChange = async (newPageNumber) => {
        setIsLoading(true);
        setPageNumber(newPageNumber);
        // await users();
        setIsLoading(true);
    };
    const handlePerPageChange = async (newPerPage, newPageNumber) => {
        setIsLoading(true);
        setDataPerPage(newPerPage);
        setPageNumber(newPageNumber);
        // await users();
        setIsLoading(false);
    };
    const NotificationsData = async () => {
        try {
            const response = await NotificationsService.AllNotifications(pageNumber, dataPerPage);
            console.log("notify", response.data.data);
            setTotalRows(response.data.totalNotification);
            setTableData(response.data.data)
            setIsLoading(true);

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        NotificationsData();
    }, [searchValue, pageNumber, dataPerPage]);
    const columns = [

        {
            name: <b>No</b>,
            selector: (row) => row._id,
            sortable: true,
        },
        {
            name: <b>Title</b>,
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: <b>Description</b>,
            selector: (row) => row.body,
            sortable: true,
        },
        {
            name: <b>ACTION</b>,
            selector: (row) => (
                <div className="d-flex">
                    <div>
                        <Link
                            to={row.path}
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-eye"></i>
                        </Link>
                    </div>
                </div>
            )
        },
    ];
    return (
        <>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">NOTIFICATIONS</h2>
                </div>
                <div className="d-flex">
                </div>
            </div>
            <div lg={12} className="w-100">
                <Card className="custom-card overflow-hidden">
                    <Card.Body>
                        <div className="responsive">
                            <Card className="custom-card overflow-hidden">
                                <div>
                                    <DataTable
                                        data={tableData}
                                        columns={columns}
                                        noHeader
                                        persistTableHead
                                        center={true}
                                        highlightOnHover
                                        striped
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={dataPerPage}
                                        onChangeRowsPerPage={(page, totalRows) => {
                                            handlePerPageChange(page, 1);
                                        }}
                                        onChangePage={handlePageChange}
                                        progressPending={isLoading}
                                        progressComponent={<CircularProgress size={30} style={{ marginTop: "50px" }} />}
                                    />
                                </div>
                            </Card>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default Notifications
