import { get } from "lodash";
import serverCall from "../../serverCall";

const signUp = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  // console.log("email,password", email, password)
  const response = await serverCall.post(`/auth/log-in?email=${email}&password=${password}`)
  // console.log("response in auth", response.data.accessToken)
  // const data = get(response.data, "data", null);
  // console.log("data", data)
  console.log("hrsdresponce", response.data);
  if (response.data.accessToken) {
    localStorage.setItem('token', response.data.accessToken);
    localStorage.setItem('storeId', response.data.data.storeId);
    localStorage.setItem('userType', response.data.data.userType);
    return response.data;
  } else {
    return response.data
  }
};
const FcmToken = async (data) => {
  try {
    const response = await serverCall.post(`/device/create-device`, data);
    return response
  } catch (e) {
    console.log("e", e);
  }
}
const AuthenticationService = {
  signUp,
  FcmToken
};

export default AuthenticationService;
