import { enqueueSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, Container, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Stock from "../../assets/img/image 8.svg";
import NotificationsService from "../../services/notifications.service";

function Header() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState();
  const name = JSON.parse(localStorage.getItem("picking"));
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  useEffect(() => {
    document.addEventListener("click", handlePageClick);

    return () => {
      document.removeEventListener("click", handlePageClick);
    };
  }, []);

  const handlePageClick = (event) => {
    const target = event.target;
    const sidebarToggle = document.getElementById("mainSidebarToggle");

    if (
      target !== sidebarToggle &&
      target.closest(".main-header-left") === null
    ) {
      closeSidebar();
    }
  };

  const closeSidebar = () => {
    const body = document.querySelector("body");
    const innerWidth = window.innerWidth;

    if (body !== null) {
      if (innerWidth >= 992) {
        body.classList.remove("main-sidebar-show");
      } else if (body.classList.contains("horizontalmenu")) {
        body.classList.remove("main-navbar-show");
      } else {
        body.classList.remove("main-sidebar-show");
      }
    }
  };

  const handleLogin = () => {
    localStorage.clear();
    enqueueSnackbar("Logout Success", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
    window.location.reload();
    closeSidebar();
  };

  const headerToggleButton = () => {
    const body = document.querySelector("body");
    const innerWidth = window.innerWidth;

    if (body !== null) {
      if (innerWidth >= 992) {
        body.classList.toggle("main-sidebar-hide");
        body.classList.remove("main-sidebar-show");
      } else if (body.classList.contains("horizontalmenu")) {
        body.classList.toggle("main-navbar-show");
        body.classList.remove("main-sidebar-show");
        body.classList.remove("main-sidebar-hide");
      } else {
        body.classList.toggle("main-sidebar-show");
        body.classList.remove("main-sidebar-hide");
      }
    }
  };
  const NotificationUnseen = async () => {
    try {
      const response = await NotificationsService.UnseenNotifications();
      setData(response.data.data);
      setCount(response.data.totalNotification);
      console.log("notify", response.data.data);
    } catch (e) {
      console.log("ehrsd", e);
    }
  };
  const NotificationSeen = async (_id) => {
    try {
      const response = await NotificationsService.SeenNotifications(_id);
      console.log("notify", response.data);
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    NotificationUnseen();
    // NotificationSeen()
  }, []);
  const TypeRole = localStorage.getItem("userType");
  const handleNotificationClick = (item) => {
    const navigatePath =
      item.title === "Stock Alert!"
        ? TypeRole === "Admin"
          ? `/supermarket/peoductdetails/${item.payload?.id}`
          : TypeRole === "SuperMarketUser"
          ? `/store/products/${item.payload?.id}`
          : TypeRole === "Admin"
          ? `/order/`
          : TypeRole === "SuperMarketUser"
        : `/order/details/${item.payload?.id}/${
            item.body.split(" ")[3] + " " + item.body.split(" ")[4]
          }`; //hrsd changes .payload?
    console.log("ittttttttt", navigatePath);

    navigate(navigatePath);

    setShow(false);
    NotificationSeen(item._id);
    NotificationUnseen();
  };
  return (
    <Fragment>
      <Navbar expand="lg" className="main-header side-header sticky">
        <Container fluid className="main-container container-fluid">
          <div className="main-header-left">
            <Link
              to="#"
              className="main-header-menu-icon"
              id="mainSidebarToggle"
              onClick={headerToggleButton}
            >
              <span></span>
            </Link>
          </div>

          <div className="main-header-right">
            <Navbar.Toggle
              aria-controls="navbarSupportedContent-4"
              className="navresponsive-toggler"
            >
              <i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
            </Navbar.Toggle>
            <div className="navbar navbar-expand-lg nav nav-item navbar-nav-right responsive-navbar navbar-dark">
              <Navbar.Collapse
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2 align-items-center ms-auto">
                  {name?.user?.Email}
                  <Dropdown className="main-profile-menu">
                    <Dropdown.Toggle className="d-flex p-0" variant="default">
                      <span className="main-img-user mx-1">
                        <img
                          alt="avatar"
                          src={require("../../../assets/img/users/male.png")}
                        />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ margin: "0px", width: "auto" }}>
                      <Dropdown.Item onClick={handleLogin}>
                        <i className="fe fe-power"></i> Sign Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Dropdown
                  className=" main-header-notification"
                  show={show}
                  onToggle={(isOpen) => setShow(isOpen)}
                >
                  <Dropdown.Toggle
                    className="nav-link icon"
                    // href="#"
                    variant="default"
                  >
                    <i className="fe fe-bell header-icons"></i>
                    <span className="badge bg-danger nav-link-badge">
                      {count}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu"
                    style={{ margin: 0 }}
                  >
                    {/* <div className="header-navheading">
                      <p className="main-notification-text">
                        You have 1 unread notification
                        <span className="badge bg-pill bg-primary ms-3">
                          View all
                        </span>
                      </p>
                    </div> */}

                    {data?.map((item, index) => (
                      <div
                        className="main-notification-list"
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          handleNotificationClick(item);
                        }}
                        // onClick={() => { (item.title === 'Stock Alert!' ? navigate(`/supermarket/peoductdetails/${item.payload?.id}`) : navigate(`/order/`)); setShow(false); NotificationSeen(item._id); NotificationUnseen(); }}
                      >
                        <div className="media new">
                          <div>
                            <div className="notification-row">
                              <div className="media-body">
                                <p>
                                  {/* "Attention! The stock level for <b>Dairy Product</b> has reached a critical level. */}
                                  <b>{item.title}</b>
                                  {item.body}
                                </p>
                                {/* <span>Oct 15 12:32pm</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="dropdown-footer">
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setShow(false);
                          navigate("/notifications/");
                        }}
                      >
                        View All Notifications
                      </Link>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </Fragment>
  );
}

export default Header;
