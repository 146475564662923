import React, { Fragment, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Product1 from '../../../assets/img/Milk bottle 1.svg'
import AddOffer from './AddOffer';
import useDialogState from '../../../hooks/useDialogState';
import img from '../../../assets/img/Illustration.svg'
import ProductEdit from './ProductEdit';
import ProductService from '../../../services/product.service';
import { useEffect } from 'react';
import { result } from 'lodash';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import * as Yup from "yup";
import CategoryService from '../../../services/categories.service';
import BrandService from '../../../services/brands.service';
import moment from 'moment';
import DeleteOfferModel from './DeleteOfferModel';
import withLoader from '../../../layout/loader/withLoader';

const ProductDetails = () => {
    const [showEdit, setShowEdit] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [image, setImage] = useState();
    const [name, setName] = useState();
    const [offerName, setOfferName] = useState('');
    const [description, setDescription] = useState();
    const [category, setCategory] = useState();
    const [brand, setBrand] = useState();
    const [price, setPrice] = useState();
    const [convertedDate, setCovertedDate] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [dropDownData, setDropDownData] = useState([]);
    const [dropDownData2, setDropDownData2] = useState([]);
    const [getAllOffer, setGetAllOffer] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewImageBanner, setPreviewImageBanner] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const { open: show, handleClickOpen: handelDialogOpen, handleClickClose: handleDialogClose } = useDialogState()
    const { productId } = useParams();
    const storeId = localStorage.getItem("SuperMarketId")
    const CategoriesOptions = [
        ...dropDownData.map((item) => ({
            value: item._id,
            label: item.categoryName
        }))
    ];

    const BrandsOptions = [
        ...dropDownData2.map((item) => ({
            value: item._id,
            label: item.brandName
        }))
    ];

    const showCategoryName = CategoriesOptions.find((res) => res.value === category)
    const showBrandName = BrandsOptions.find((res) => res.value === brand)

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        formik2.setFieldValue("isBanner", e.target.checked)
    };
    const handleBannerImage = (e) => {
        const imgFile = e.target.files[0];
        console.log("Img", imgFile);
        formik2.setFieldValue('bannerImage', imgFile);
        setPreviewImage(URL.createObjectURL(imgFile));
    }
    const initialValue = {
        storeId: '',
        categoryId: '',
        brandId: '',
        productName: '',
        productImage: null,
        price: '',
        description: '',
        quantity: ''
    }
    const validationSchema = Yup.object().shape({
        // storeId: Yup.string().required('Please enter Store Id!'), 
        categoryId: Yup.string().required('Please select a Category!'),
        brandId: Yup.string().required('Please select a Brand!'),
        productName: Yup.string().required('Please enter the Name!'),
        productImage: Yup.mixed().required('Please Select an Image!'),
        price: Yup.string().required('Please enter the Price!'),
        description: Yup.string().required('Please enter the Description!'),
        quantity: Yup.string().matches(/^[0-9]/).required('Please enter the Quantity!'),

    })
    const handleFormSubmit = async (values, action) => {
        // if (!values._id) {
        try {
            const formData = new FormData();
            formData.append('storeId', storeId);
            formData.append('categoryId', values.categoryId);
            formData.append('brandId', values.brandId);
            formData.append('productName', values.productName);
            formData.append('productImage', values.productImage);
            formData.append('price', values.price);
            formData.append('description', values.description);

            formData.append("quantity", values.quantity);
            const response = await ProductService.Update(values._id, formData);
            console.log("product", response);
            action.resetForm();
            setShowEdit(false);
            DetailsOfProduct()
            enqueueSnackbar(response.data.message, {
                variant: "success", anchorOrigin: {
                    vertical: "bottom", horizontal: "right"
                }
            })
        } catch (e) {
            console.log("e", e);
            enqueueSnackbar(e.response.data.message, {
                variant: "error", anchorOrigin: {
                    vertical: "bottom", horizontal: "right"
                }
            });
        }
    }
    const initialValue2 = {
        // productId: '',
        expiryDate: '',
        discount: '',
        token: '',
        isBanner: false,
        bannerImage: null
    }
    const validationSchema2 = Yup.object().shape({
        expiryDate: Yup.string().required('Please select a Expiry Date!'),
        discount: Yup.string().required('Please enter the Discount!'),
        token: Yup.string().required('Please enter the Token!'),
        // bannerImage: Yup.mixed().required('Please Select an Image!!'),
    })
    const handleFormSubmit2 = async (values, action) => {
        console.log('values', values)
        const { token, bannerImage, discount, isBanner, expiryDate } = values
        console.log("img", bannerImage);
        if (!values._id) {
            try {
                const formData = new FormData();
                formData.append('productId', productId);
                formData.append('expiryDate', values.expiryDate);
                formData.append('discount', values.discount);
                formData.append('token', values.token);
                formData.append('bannerImage', values.bannerImage);
                formData.append('isBanner', values.isBanner);
                const response = await ProductService.CreateOffer(formData);
                action.resetForm();
                handleDialogClose();
                DetailsOfProduct()
                console.log("response", response);
            } catch (e) {
                console.log("e", e);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append('productId', productId);
                formData.append('expiryDate', values.expiryDate);
                formData.append('discount', values.discount);
                formData.append('token', values.token);
                formData.append('bannerImage', values.bannerImage);
                formData.append('isBanner', values.isBanner);
                const response = await ProductService.UpdateOffer(values._id, formData);
                action.resetForm();
                handleDialogClose();
                DetailsOfProduct()
                console.log("response", response);
            }
            catch (e) {
                console.log("e", e);
            }
        }
    }
    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: validationSchema,
        onSubmit: handleFormSubmit
    })
    const formik2 = useFormik({
        initialValues: initialValue2,
        validationSchema: validationSchema2,
        onSubmit: handleFormSubmit2
    })
    const DetailsOfProduct = async () => {
        try {
            const response = await ProductService.GetOne(productId);
            const result = response.data.data
            const date = moment(result.offer.expiryDate).format("DD/MM/YYYY")
            setCovertedDate(date);
            setGetAllOffer(result.offer);
            setImage(result.productImage);
            setName(result.productName);
            setDescription(result.description);
            setPrice(result.price);
            setCategory(result.categoryId);
            setBrand(result.brandId);

        } catch (e) {
            console.log("e", e);
        }
    }
    const handleEditProduct = async (id) => {
        console.log("id", id);
        if (id) {

            setShowEdit(true);
            setIsUpdate(true);
            const response = await ProductService.GetOne(id);
            const result = response.data.data
            formik.setFieldValue("storeId", result.storeId);
            formik.setFieldValue("categoryId", result.categoryId);
            formik.setFieldValue("brandId", result.brandId);
            formik.setFieldValue("productName", result.productName);
            formik.setFieldValue("price", result.price);
            formik.setFieldValue("productImage", result.productImage);
            formik.setFieldValue("description", result.description);

            formik.setFieldValue("quantity", result.quantity);
            formik.setFieldValue("_id", result._id);
            setPreviewImage(result.productImage)
        } else {
            setIsUpdate(false);
            setPreviewImage(null);
            formik.resetForm()
        }
    }
    const handleDialogCloseReset = () => {
        handleDialogClose()
        setIsChecked(false)
        formik2.resetForm();
    }
    const handleImageChange = (e) => {
        const imgFile = e.target.files[0];
        formik.setFieldValue('productImage', imgFile);
        console.log("Images", URL.createObjectURL(e.target.files[0]));
        setPreviewImage(URL.createObjectURL(imgFile));
    }
    const AllCategories = async () => {
        try {
            const response = await CategoryService.GetCategoryList();
            setDropDownData(response.data.data);
        } catch (e) {
            console.log("e", e);
        }
    };
    const AllBrands = async () => {
        try {
            const response = await BrandService.GetBrandList();
            setDropDownData2(response.data.data);
        } catch (e) {
            console.log("e", e);
        }
    };

    useEffect(() => {
        AllCategories();
        AllBrands();
        DetailsOfProduct();
    }, []);

    const handleDialogOpen = async (id) => {
        handelDialogOpen()
        if (id) {
            setIsUpdate(true)
            const response = await ProductService.GetOneOffer(id);
            const result = response.data.data
            // const date = moment(result.expiryDate).format("DD/MM/YYYY")
            console.log("expiry date", result);
            formik2.setFieldValue("expiryDate", result.expiryDate);
            formik2.setFieldValue("discount", result.discount);
            formik2.setFieldValue("token", result.token);
            formik2.setFieldValue("isBanner", result.isBanner);
            formik2.setFieldValue("bannerImage", result.bannerImage);
            formik2.setFieldValue("_id", result._id);
            setPreviewImageBanner(result.bannerImage);
            setIsChecked(result.isBanner);
        } else {
            setIsUpdate(false)
            formik2.resetForm();
            setPreviewImageBanner(null);
            setIsChecked(false);
        }
    }
    const handleDelete = (id, discount) => {
        setShowDelete(true);
        setDeleteId(id);
        setOfferName(discount)
    }
    return (
        <Fragment>
            {/* <!-- Page Header --> */}
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">Product-Details</h2>
                </div>
                <div>
                    <Button
                        className="mx-2"
                        style={{ height: "28px" }}
                        onClick={() => handleEditProduct(productId)}
                    >
                        <i className="fe fe-edit"></i>
                    </Button>
                    <Button
                        variant="primary"
                        type="button"
                        className="my-2 btn-icon-text"
                        onClick={() => handleDialogOpen('')}
                    >
                        <i className="fe fe-plus me-2"></i>ADD OFFER
                    </Button>
                </div>
            </div>
            <Row className="row-sm">
                {/* {productDetails.map((data, index) => ( */}
                <Col md={4} xl={3}>
                    <Card className=" custom-card productdesc">
                        <Card.Body className="h-100">
                            <Row className="align-items-center">
                                <div className="product-carousel">
                                    <div
                                        id="carousel"
                                        className="carousel slide"
                                        data-bs-ride="false"
                                    >
                                        <div className="carousel-inner">
                                            <div>
                                                <img
                                                    src={image}
                                                    alt="img"
                                                    className="img-fluid mx-auto d-block"
                                                    style={{ width: '60%' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="mt-1 mb-1">
                                                {name}
                                            </h4>
                                            <p className="text-muted mb-3">{description}</p>
                                            <h4 className="mb-2">
                                                <b>{price}</b>
                                            </h4>
                                            <h6 className="mt-4 fs-20">Description</h6>
                                            <p className="text-muted m-0">Categorie: <span>
                                                {showCategoryName?.label}

                                            </span></p>
                                            <p className="text-muted m-0">Brand: <span>{showBrandName?.label}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
                {/* ))} */}
                <Col md={8} xl={9} >
                    <Row>
                        {getAllOffer.map((data, index) => (
                            <Col xl={4}>
                                <Card className='mb-4'>
                                    <Card.Body className='pb-0'>
                                        {/* <Row> */}
                                        {/* <Col > */}
                                        <div className='d-flex justify-content-between'>
                                            <h5 className="m-0">
                                                {data.expiryDate}
                                            </h5>
                                            <h6 className="text-danger">{data.days} Days left</h6></div>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <div>
                                                <img src={img} alt='img' height={60} />
                                            </div>
                                            <div className='d-flex'>
                                                <div className='direction-row align-start me-4'>
                                                    <h3 className="text-success text-uppercase m-0"><b>{data.discount}%</b></h3>
                                                    <p className="text-muted">Discount</p>
                                                </div>
                                                <div className='direction-row align-start'>
                                                    <h3 className="text-success text-uppercase m-0"><b>{data.token}</b></h3>
                                                    <p className="text-muted">Token</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                    <div className='d-flex align-items-end my-2'>
                                        <button
                                            className="ms-auto btn btn-primary btn-sm me-1"
                                            style={{ height: "28px" }}
                                            onClick={() => handleDialogOpen(data._id)}
                                        >
                                            <i className="fe fe-edit"></i>
                                        </button>
                                        <div className="me-2 align-items-baseline">
                                            <button className='btn btn-danger btn-sm'
                                                onClick={() => handleDelete(data._id, data.discount)}
                                            >
                                                <i className='fa fa-trash'></i>
                                            </button>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    {show && (<AddOffer formik2={formik2} isUpdate={isUpdate} show={show} handleDialogClose={handleDialogCloseReset} isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} handleBannerImage={handleBannerImage} previewImageBanner={previewImageBanner} />)}
                    {showEdit && (<ProductEdit formik={formik} isUpdate={isUpdate} CategoriesOptions={CategoriesOptions} BrandsOptions={BrandsOptions} handleImageChange={handleImageChange} previewImage={previewImage} show={showEdit} handleDialogClose={() => setShowEdit(false)} />)}
                    {showDelete && <DeleteOfferModel show={showDelete} onHide={() => setShowDelete(false)} offerName={offerName} deleteId={deleteId} DetailsOfProduct={DetailsOfProduct} />}

                </Col>
            </Row>
        </Fragment >
    )
}

export default withLoader(ProductDetails)
