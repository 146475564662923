import React, { Fragment, useState } from "react";
import { Button, Col, Form, Row, Container, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { login, setLoggedIn } from "../store/slice/auth";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import AuthenticationService from "../services/auth.service";
import * as Yup from 'yup';
import { get } from "lodash";
import { useEffect } from "react";
// import { getFirebaseToken, onForegroundMessage, onMessageListener, requestFirebaseNotificationPermission } from "../../firebase";
import CustomeToken from "./CustomeToken";
import { toast } from "react-toastify";

const SignIn = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({ title: "", body: "" })
  const { enqueueSnackbar } = useSnackbar();
  console.log("notification", notification);
  const formerr = {
    color: "#ff3300",
    fontSize: "16px",
    fontWeight: "500px",
  };

  const schema = object({
    password: Yup.string()
      // .min(6, 'Password must be 6 character length')
      .required('Password is required!'),
    email: Yup.string()
      .required('Email Or Username is required!'),
  });
  const initialValue = {
    email: '',
    password: '',
  };

  const GetNotification = async () => {
    try {
      const newToken = localStorage.getItem("fcmToken")
      const type = 'Web'
      const response = await AuthenticationService.FcmToken({
        deviceType: type,
        deviceToken: newToken
      });
      console.log("responseNotify", response);
    } catch (e) {
      console.log("e", e);
    }
  }
  const handleFormSubmit = async (values, action) => {
    const { email, password } = values;
    try {
      const response = await AuthenticationService.signUp({ email, password });
      dispatch(setLoggedIn(true));
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("email", email);
      // onForegroundMessage()
      //   .then((payload) => {
      //     console.log("Received foreground message: ", payload);
      //     const {
      //       notification: { title, body },
      //     } = payload;
      //     toast(<ToastifyNotification title={title} body={body} />);
      //   })
      //   .catch((err) =>
      //     console.log(
      //       "An error occured while retrieving foreground message. ",
      //       err
      //     )
      //   );
      // GetNotification();
      if (response.data.email === 'admin@gmail.com') {
        navigate('/dashboard/')
      }
      else if (response.data.email === 'supermarketuser@gmail.com') {
        navigate('/store/dashboard/')
      }
      else if (response.data.email === 'teammember@gmail.com') {
        navigate('/team/orders/')

      }
      else {
        localStorage.clear()
        enqueueSnackbar("Invalid Credentials!", {
          variant: "error", anchorOrigin: {
            vertical: "bottom", horizontal: "right"
          }
        })
      }
      action.resetForm();
      enqueueSnackbar("Login Success", {
        variant: "success", anchorOrigin: {
          vertical: "bottom", horizontal: "right"
        }
      });
      window.location.reload();

    } catch (e) {
      action.resetForm();
      navigate("/login");
      console.log("e", e);
      enqueueSnackbar("Invalid credentials ", {
        variant: "error", anchorOrigin: {
          vertical: "bottom", horizontal: "right"
        }
      });
    }
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleFormSubmit

  })
  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );
  return (
    <>
      <Fragment>
        <div className="page main-signin-wrapper">
          <Row className="signpages text-center">
            <Col md={12}>
              <Card>
                <Row className="row-sm">
                  <Col
                    lg={6}
                    xl={5}
                    className="d-none d-lg-block text-center bg-primary details"
                  >
                    <div className="mt-5 pt-4 p-2 pos-absolute">
                      <div className="clearfix"></div>
                      <img
                        src={require("../../assets/img/svgs/user.svg").default}
                        className="ht-100 mb-0"
                        alt="user"
                      />
                      <h5 className="mt-4 text-white">Create Your Account</h5>
                      <span className="tx-white-6 tx-13 mb-5 mt-xl-0">
                        Signup to create, discover and connect with the global
                        community
                      </span>
                    </div>
                  </Col>
                  <Col lg={6} xl={7} xs={12} sm={12} className="login_form ">
                    <Container fluid>
                      <Row className="row-sm">
                        <Card.Body className="mt-2 mb-2 text-center">
                          <form onSubmit={formik.handleSubmit} noValidate>
                            <h5 className="mb-2">
                              Signin to Your Account
                            </h5>
                            <p className="mb-4 text-muted tx-13 ms-0">
                              Signin to create, discover and connect with the
                              global community
                            </p>
                            <Form.Group
                              className="text-start form-group"
                              controlId="formEmail"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                className="form-control"
                                placeholder="Enter your email"
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                              />
                              {formik.errors.email && formik.touched.email ? (
                                <p style={formerr}>{formik.errors.email}</p>
                              ) : null}
                            </Form.Group>
                            <Form.Group
                              className="text-start form-group"
                              controlId="formpassword"
                            >
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                className="form-control"
                                placeholder="Enter your password"
                                name="password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                              />
                              {formik.errors.password && formik.touched.password ? (
                                <p style={formerr}>{formik.errors.password}</p>
                              ) : null}
                            </Form.Group>
                            <Button
                              type="submit"
                              className="btn ripple btn-main-primary btn-block mt-2"
                            >
                              Sign In
                            </Button>
                          </form>
                        </Card.Body>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Fragment>
    </>
  );
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
