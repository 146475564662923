import React from 'react'
import ProductService from '../../../services/product.service';
import { enqueueSnackbar } from 'notistack';
import { Button, Modal } from 'react-bootstrap';

const ProductDeleteModal = (props) => {
    const { name, deleteId, onHide, AllProducts, } = props
    const DeleteProduct = async () => {
        const response = await ProductService.Delete(deleteId);
        onHide();
        AllProducts();
        enqueueSnackbar("Deleted User", {
            variant: "success", anchorOrigin: {
                vertical: "bottom", horizontal: "right"
            }
        });
    }
    return (
        <>
            <Modal
                {...props}
                // show={showDelete}
                onHide={props.onHide}
                size="large"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

                    <h4 className="tx-danger tx-semibold mg-b-20">Delete?</h4>
                    <p className="mg-b-20 mg-x-20 ">
                        Are you sure want to delete <b>{name}</b> ?
                    </p>

                    <Button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            DeleteProduct();
                        }}
                        variant="danger"
                    >
                        Delete
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ProductDeleteModal
