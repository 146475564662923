import serverCall from '../../serverCall'

const CreateBrand = async (data) => {
    try {

        const response = await serverCall.post("/brand/admin/create-brand", data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const GetBrandList = async (data) => {
    try {
        const response = await serverCall.get("/brand/get-all-brand", data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const GetAllBrand = async (page, limit, data) => {
    try {
        const response = await serverCall.get(`/brand/get-all-brand?page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const GetOne = async (id) => {
    try {
        const response = await serverCall.get(`/brand/get-brand/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const Update = async (id, data) => {
    try {
        const response = await serverCall.patch(`/brand/admin/update-brand/${id}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const Delete = async (id) => {
    try {
        const response = await serverCall.delete(`/brand/admin/delete-brand/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const BrandService = {
    CreateBrand,
    GetAllBrand,
    GetBrandList,
    GetOne,
    Update,
    Delete
};
export default BrandService;