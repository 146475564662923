import { Button, Modal } from "react-bootstrap";
import { enqueueSnackbar } from "notistack";

export function DeleteUserModal(props) {
    const { deleteModal, userId, onHide, allData, emailUse } = props;
    const userDelete = async () => {
        // const response = await UserService.DeleteUser(userId);
        onHide();
        enqueueSnackbar("Deleted User", {
            variant: "success", anchorOrigin: {
                vertical: "bottom", horizontal: "right"
            }
        });
        allData();
    };

    return (
        <>
            <Modal
                {...props}
                show={deleteModal}
                onHide={onHide}
                size="large"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton />
                <Modal.Body className="text-center">
                    <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

                    <h4 className="tx-danger tx-semibold mg-b-20">Delete?</h4>
                    <p className="mg-b-20 mg-x-20 ">
                        Are you sure want to delete {userId}?
                    </p>

                    <Button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            userDelete();
                        }}
                        variant="danger"
                    >
                        Delete
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
}