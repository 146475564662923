import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';

const CategorieEditModel = (props) => {
    const { formik, show, handleDialogClose, isUpdate, handleImageChange, previewImage } = props;

    return (
        <Modal
            {...props}
            show={show}
            onHide={handleDialogClose}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <h4 className="mt-3 align-items-center">{isUpdate ? "UPDATE CATEGORIE" : "ADD CATEGORIE"}</h4>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="col-122">
                    <div className="card custom-card">
                        <form
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="col-12">
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Name: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="categoryName"
                                        placeholder="Name"
                                        value={formik.values.categoryName}
                                        onChange={formik.handleChange}
                                    />
                                    {props.formik.errors.categoryName &&
                                        props.formik.touched.categoryName ? (
                                        <p className="text-start error">
                                            {props.formik.errors.categoryName}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                ><Form.Label
                                    style={{
                                        textAlign: "start",
                                        color: "#000",
                                        marginTop: "15px",
                                    }}
                                >
                                        Image: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="categoryImage"
                                        // value={props.formik.values.categoryImage}
                                        onChange={handleImageChange}

                                    />
                                    {props.formik.errors.categoryImage &&
                                        props.formik.touched.categoryImage ? (
                                        <p className="text-start error">
                                            {props.formik.errors.categoryImage}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                {previewImage && (
                                    <div>
                                        {/* <p>Preview:</p> */}
                                        <img src={previewImage} alt="Preview" height={150} />
                                    </div>
                                )}
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <Button
                                    type="submit"
                                    style={{
                                        display: "flex",
                                        marginLeft: "auto",
                                        marginRight: "15px",
                                        marginTop: "20px",
                                        textAlign: 'center',
                                    }}
                                >
                                    {isUpdate ? 'Update' : 'Submit'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
}

export default CategorieEditModel
