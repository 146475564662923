import serverCall from "../../serverCall";

const CreateSuperMarket = async (data) => {
  try {
    const response = await serverCall.post("/store/admin/create-store", data);
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const GetAllSuperMarkets = async (page, limit, data) => {
  try {
    const response = await serverCall.get(
      `/store/admin/get-all-store?page=${page}&limit=${limit}`,
      data
    );
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const GetOne = async (id) => {
  try {
    const response = await serverCall.get(`/store/get-store/${id}`);
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const Update = async (id, data) => {
  try {
    const response = await serverCall.patch(
      `/store/admin/update-store/${id}`,
      data
    );
    return response;
  } catch (e) {
    console.log("e", e);
  }
};
const Delete = async (id) => {
  try {
    const response = await serverCall.delete(`/store/admin/delete-store/${id}`);
    return response;
  } catch (e) {
    console.log("e", e);
  }
};

const SuperMarketService = {
  CreateSuperMarket,
  GetAllSuperMarkets,
  GetOne,
  Update,
  Delete,
};
export default SuperMarketService;
