import serverCall from '../../serverCall'

const AllProducts = async (id, page, limit, data) => {
    try {
        const response = await serverCall.get(`/product/get-product-storedWise?storeId=${id}&page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}

const StoreProductService = {
    AllProducts,
};
export default StoreProductService;