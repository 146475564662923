import React, { useState } from 'react'
import { Button, Card, Col, Pagination, Row } from 'react-bootstrap';
import useDialogState from '../../../hooks/useDialogState';
import Loader1 from '../../../../assets/img/svgs/loader.svg'
import { useEffect } from 'react';
import BrandService from '../../../services/brands.service';
import * as Yup from "yup";
import { enqueueSnackbar } from 'notistack';
import { useFormik } from 'formik';
import DeleteModal from './DeleteModal';
import BrandEditModel from './BrandEditModal';

const Brands = () => {
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [brandName, setBrandName] = useState('');
    const [page, setPage] = useState(1);
    const [isUpdate, setIsUpdate] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [brandData, setBrandData] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { open: show, handleClickOpen: handelDialogOpen, handleClickClose: handleDialogClose } = useDialogState()

    const limit = 12
    const nextPage = (page) => {
        setPage(page);
    };
    const previousPage = (page) => {
        setPage(page);
    };
    const initialValue = {
        brandName: '',
        brandImage: null
    }
    const validationSchema = Yup.object().shape({
        brandImage: Yup.mixed().required('Please Select an Image!!'),
        brandName: Yup.string().required('Please enter the Name!')
    })
    const handleFormSubmit = async (values, action) => {
        if (!values._id) {
            try {
                const formData = new FormData()
                formData.append('brandName', values.brandName);
                formData.append('brandImage', values.brandImage);
                const response = await BrandService.CreateBrand(formData);
                action.resetForm();
                AllBrands();
                handleDialogClose()
                console.log("brand", response);
                enqueueSnackbar(response.data.message, {
                    variant: "success", anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                })
            } catch (e) {
                console.log("e", e);
                enqueueSnackbar(e.response.data.message, {
                    variant: "error", anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                });
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("brandName", values.brandName);
                formData.append("brandImage", values.brandImage);
                const response = await BrandService.Update(values._id, formData);
                action.resetForm();
                AllBrands();
                handleDialogClose();
                enqueueSnackbar(response.data.message, {
                    variant: "success", anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                })
                console.log("Update", response.data);
            } catch (e) {
                enqueueSnackbar(e.response.data.message, {
                    variant: "error", anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                });
                console.log("e", e);
            }

        }
    }
    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: validationSchema,
        onSubmit: handleFormSubmit
    })
    const handleImageChange = (e) => {
        const imgFile = e.target.files[0];
        formik.setFieldValue('brandImage', imgFile);
        console.log("Images", URL.createObjectURL(imgFile));
        setPreviewImage(URL.createObjectURL(imgFile));
    }
    const handleDelete = (id, brandName) => {
        setShowDelete(true);
        setDeleteId(id);
        setBrandName(brandName);
    }
    const handleDialogOpen = async (id) => {
        handelDialogOpen()
        if (id) {
            console.log("id", id);
            setIsUpdate(true);
            const response = await BrandService.GetOne(id);
            const result = response.data.data
            console.log("update", result);
            formik.setFieldValue("brandName", result.brandName);
            formik.setFieldValue("brandImage", result.brandImage);
            formik.setFieldValue("_id", result._id)
            setPreviewImage(result.brandImage);
        } else {
            setIsUpdate(false);
            setPreviewImage(null);
            formik.resetForm()
        }
    }
    const handleDialogCloseReset = () => {
        handleDialogClose()
        formik.resetForm();
    }
    const AllBrands = async () => {
        try {
            setIsLoading(true);
            const response = await BrandService.GetAllBrand(page, limit);
            setIsLoading(false);
            console.log("Response", response.data.data);
            setBrandData(response.data.data);
            setTotalRows(response.data.totalBrand)
        } catch (e) {
            console.log("e", e);
        }
    };

    useEffect(() => {
        AllBrands();
    }, [page]);
    return (
        <>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">BRANDS</h2>
                </div>
                <div className="d-flex">
                    <div className="justify-content-center">

                        {showDelete && <DeleteModal show={showDelete} onHide={() => setShowDelete(false)} brandName={brandName} deleteId={deleteId} AllBrands={AllBrands} />}

                    </div>
                </div>
            </div>
            <div lg={12} className="w-100">

                {show && (
                    <BrandEditModel formik={formik} handleImageChange={handleImageChange} previewImage={previewImage}
                        show={show} handelDialogOpen={handelDialogOpen} handleDialogClose={handleDialogCloseReset} isUpdate={isUpdate}
                    />
                )}
                <Row className="row row-sm">
                    <div className='d-flex justify-content-end'>
                        <Button
                            variant="primary"
                            type="button"
                            className="my-2 btn-icon-text"
                            onClick={() => handleDialogOpen('')}
                        >
                            <i className="fe fe-plus me-2"></i>ADD
                        </Button>
                    </div>
                    {isLoading ? (<img src={Loader1} alt="Loader" height={70} />) : (
                    <>

{brandData.length === 0 ? (
                            <div className="text-center mt-3 mb-3">
                                <p>No brands available. Click the "Add" button to create a new brand.</p>
                            </div>
                        ) : (<>



                        {brandData.map((data, index) => (
                            <>
                                <Col sm={6} md={4} xl={2} xs={12} className="mb-5" key={index}>
                                    <div>
                                        <Card className="custom-card mb-1 align-items-center">
                                            <img
                                                src={data.brandImage}
                                                height={100}
                                                style={{
                                                    position: "relative",
                                                    objectFit: "cover",
                                                }}
                                                alt="brandimage"
                                                className="brand-image my-4"
                                            />
                                            <div
                                                style={{ width: "100%" }}
                                            >
                                                <div className='d-flex flex-column'>
                                                    <div className="d-flex justify-content-center">
                                                        <label className="tx-18 fw-bold">{data.brandName}</label></div>
                                                    <div className='d-flex align-items-end my-2'>
                                                        <button
                                                            className="ms-auto btn btn-primary btn-sm me-1"
                                                            style={{ height: "28px" }}
                                                            onClick={() => handleDialogOpen(data._id)}
                                                        >
                                                            <i className="fe fe-edit"></i>
                                                        </button>
                                                        <div className="me-2 align-items-baseline">
                                                            <button className='btn btn-danger btn-sm'
                                                                onClick={() => { handleDelete(data._id, data.brandName) }}
                                                            >
                                                                <i className='fa fa-trash'></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            </>
                        ))}
                    </>)}
                    </>
                    )}
                    {brandData != "" && (
                        <Pagination
                            className="pagination-circle mb-0 ms-lg-3"
                            style={{ justifyContent: "center" }}
                        >
                            <Pagination.Item
                                onClick={() => previousPage(page - 1)}
                                disabled={page === 1}
                            >
                                <i className="fas fa-angle-double-left"></i>
                            </Pagination.Item>
                            {Array.from({ length: Math.ceil(totalRows / limit) }).map(
                                (_, index) => {
                                    const pageNumber = index + 1;
                                    return (
                                        <Pagination.Item
                                            onClick={() => nextPage(pageNumber)}
                                            key={pageNumber}
                                            active={pageNumber === page}
                                        >
                                            {pageNumber}
                                        </Pagination.Item>
                                    );
                                }
                            )}
                            <Pagination.Item
                                onClick={() => nextPage(page + 1)}
                                disabled={page === Math.ceil(totalRows / limit)}

                            >
                                <i className="fas fa-angle-double-right"></i>
                            </Pagination.Item>
                        </Pagination>
                    )}
                </Row>
            </div >
        </>
    )
}

export default Brands
