import { Box, Chip, MenuItem, OutlinedInput } from '@mui/material';
import Select from 'react-select'
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';

const ProductEdit = (props) => {
    const { formik, show, handleDialogClose, isUpdate, CategoriesOptions, BrandsOptions, handleImageChange, previewImage } = props;
    const handleQuantity = async (event, data) => {
        let inputValue = event.target.value;
        const numericValue = inputValue.replace(/[^0-9.]/g, "");
        formik.setFieldValue("quantity", numericValue);
    }
    const handlePrice = async (event, data) => {
        let inputValue = event.target.value;
        const numericValue = inputValue.replace(/[^0-9.]/g, "");
        formik.setFieldValue("price", "$" + numericValue);
    }
    function dec(el) {
        handleQuantity()
        let input = el.target.parentElement.querySelector("input");
        let unit = Number(input.value);
        if (unit > 1) {
            input.value = unit - 1;
        }
        formik.setFieldValue("quantity", input.value);
    }

    function inc(el) {
        handleQuantity()
        // props.formik.setFieldValue("quantity", el);
        let input = el.target.parentElement.querySelector("input");
        let unit = Number(input.value++)
        // Number(el.target.parentElement.querySelector("input").value++);
        formik.setFieldValue("quantity", input.value);
    }
    return (
        <Modal
            {...props}
            onHide={handleDialogClose}
            size="large"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <h4 className="mt-3 align-items-center">{isUpdate ? "UPDATE" : "ADD"}</h4>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="col-122">
                    <div className="card custom-card">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-12">
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Name: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="productName"
                                        placeholder="Name"
                                        value={formik.values.productName}
                                        onChange={formik.handleChange}
                                    />
                                    {props.formik.errors.productName &&
                                        props.formik.touched.productName ? (
                                        <p className="text-start error">
                                            {props.formik.errors.productName}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Price: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="price"
                                        placeholder="Price"
                                        value={formik.values.price.slice(1)}
                                        onChange={handlePrice}
                                    />
                                    {props.formik.errors.price &&
                                        props.formik.touched.price ? (
                                        <p className="text-start error">
                                            {props.formik.errors.price}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Description: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        placeholder="Description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                    />
                                    {props.formik.errors.description &&
                                        props.formik.touched.description ? (
                                        <p className="text-start error">
                                            {props.formik.errors.description}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Categories: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Select
                                        style={{ width: "100%" }}
                                        value={CategoriesOptions.find((res) => res.value === props.formik.values.categoryId)}
                                        placeholder="Choose one"
                                        options={CategoriesOptions}
                                        onChange={(selected) => {
                                            props.formik.setFieldValue("categoryId", selected.value);
                                        }}
                                    />
                                    {props.formik.errors.categoryId &&
                                        props.formik.touched.categoryId ? (
                                        <p className="text-start error">
                                            {props.formik.errors.categoryId}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                >
                                    <Form.Label
                                        style={{
                                            textAlign: "start",
                                            color: "#000",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Brands: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Select
                                        style={{ width: "100%" }}
                                        value={BrandsOptions.find((res) => res.value === props.formik.values.brandId)}
                                        placeholder="Choose one"
                                        options={BrandsOptions}
                                        //  onMenuOpen={handleOpenMenu}
                                        //  onMenuClose={handleCloseMenu}
                                        //  menuIsOpen={isOpen}
                                        onChange={(selected) => {
                                            props.formik.setFieldValue("brandId", selected.value);
                                        }}
                                    />
                                    {props.formik.errors.brandId &&
                                        props.formik.touched.brandId ? (
                                        <p className="text-start error">
                                            {props.formik.errors.brandId}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group controlId='validationFormik101' className='position-relative'>
                                    <Form.Label style={{
                                        textAlign: "start",
                                        color: "#000",
                                        marginTop: "15px"
                                    }}>Quantity: <span className='tx-danger'>*</span></Form.Label>
                                    <div className="handle-counter" id="handleCounter">
                                        <Button
                                            variant="light"
                                            name='quantity'
                                            className="counter-minus "
                                            value={props.formik.values.quantity}
                                            onClick={dec}
                                        >
                                            -
                                        </Button>
                                        <input
                                            type="text"
                                            name='quantity'
                                            value={props.formik.values.quantity}
                                            onChange={handleQuantity}
                                        />
                                        <Button
                                            variant="light"
                                            name='quantity'
                                            className="counter-plus "
                                            value={props.formik.values.quantity}
                                            onClick={inc}
                                        >
                                            +
                                        </Button>
                                    </div>
                                    {props.formik.errors.quantity &&
                                        props.formik.touched.quantity ? (
                                        <p className="text-start error">
                                            {props.formik.errors.quantity}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    controlId="validationFormik101"
                                    className="position-relative"
                                ><Form.Label
                                    style={{
                                        textAlign: "start",
                                        color: "#000",
                                        marginTop: "15px",
                                    }}
                                >
                                        Image: <span className="tx-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="productImage"
                                        onChange={handleImageChange}
                                    />
                                    {props.formik.errors.productImage &&
                                        props.formik.touched.productImage ? (
                                        <p className="text-start error">
                                            {props.formik.errors.productImage}
                                        </p>
                                    ) : null}
                                </Form.Group>
                                {previewImage && (
                                    <div>
                                        <img src={previewImage} alt="Preview" height={150} />
                                    </div>
                                )}
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <Button
                                    type="submit"
                                    style={{
                                        display: "flex",
                                        marginLeft: "auto",
                                        marginRight: "15px",
                                        marginTop: "20px",
                                        textAlign: 'center',
                                    }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
}

export default ProductEdit
