import serverCall from '../../serverCall'

const GraphData = async (data) => {
    try {
        const response = await serverCall.get('/users/admin/graph', data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}

const DashboardService = {
    GraphData,
};
export default DashboardService;