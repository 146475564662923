import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup'
// import AddUser from './AddUserModal';
// import { DeleteUserModal } from './DeleteUserModal';
import DataTable from 'react-data-table-component';
import { CircularProgress } from '@mui/material';
import withLoader from '../../../layout/loader/withLoader';
import NotificationsService from '../../../services/notifications.service';

const Notifications = () => {
    const { state } = useLocation();
    const [Name, setName] = useState(state?.Name)
    const [dataPerPage, setDataPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [totalRows, setTotalRows] = useState();
    const [tableData, setTableData] = useState();
    const navigate = useNavigate();

    // const customNoDataComponent = () => (
    //     <div style={{ textAlign: "center" }}>
    //         <p>Wait A Few Seconds</p>

    //     </div>
    // );

    const NotificationsData = async () => {
        try {
            const response = await NotificationsService.AllNotifications(pageNumber, dataPerPage);
            console.log("notify", response.data.data);
            setTotalRows(response.data.totalNotification);
            setTableData(response.data.data)
            setIsLoading(true);

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        NotificationsData();
        window.scrollTo(0, 0);
    }, [pageNumber, dataPerPage]);


    const handleLinkClick = (row) => {
        if (row.title === 'Stock Alert!') {
            const productId = row.payload?.id;
            navigate(`/supermarket/peoductdetails/${productId}`);
        } else {
            navigate(`/order/details/${row.payload?.id}/${row.body.split(" ")[3]} ${row.body.split(" ")[4]}`, {
                state: {
                    Id: row._id,
                    Name: row.body.split(" ")[3] + " " + row.body.split(" ")[4],
                }
            });
        }
    };
    


    const handlePerPageChange = async (newPerPage, newPageNumber) => {
        setIsLoading(true);
        setDataPerPage(newPerPage);
        setPageNumber(newPageNumber);
        await NotificationsData();
        setIsLoading(false);
    };
    const handlePageChange = async (newPageNumber) => {
        setIsLoading(true);
        setPageNumber(newPageNumber);
        await NotificationsData();
        setIsLoading(true);
    };
    const columns = [

        {
            name: <b>No</b>,
            selector: (row) => row._id,
            sortable: true,
        },
        {
            name: <b>Title</b>,
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: <b>Description</b>,
            selector: (row) => row.body,
            sortable: true,
        },
        {
            name: <b>ACTION</b>,
            selector: (row) => (
                <div className="d-flex">
                    <div>
                        <Link
                            // to={(row.title === 'Stock Alert!' ? navigate(`/supermarket/peoductdetails/${row.payload?.id}`) : navigate(`/order/details/${row.payload?.id}/${row.body.split(" ")[3]} ${row.body.split(" ")[4]}`))}
                            
                            // state={{
                            //     Id: row._id,
                            //     Name: row.body.split(" ")[3] + " " + row.body.split(" ")[4]
                            // }}
                            className="btn btn-primary btn-sm"
                            onClick={(e) => {
                                e.preventDefault();
                                handleLinkClick(row); // Navigate based on conditions
                            }}
                        >
                            <i className="fa fa-eye"></i>
                        </Link>
                        {/* <Link to={`/order/details/${row.payload?.id}/${row.body.split(" ")[3]} ${row.body.split(" ")[4]}`} state={{
                            Id: row._id,
                            Name: row.body.split(" ")[3] + " " + row.body.split(" ")[4]
                        }} className='btn btn-primary btn-sm' >
                            <i className='fas fa-eye'></i>
                        </Link> */}
                    </div>
                </div>
            )
        },
    ];
    return (
        <>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">NOTIFICATIONS</h2>
                </div>
                <div className="d-flex">
                </div>
            </div>
            <div lg={12} className="w-100">
                <Card className="custom-card overflow-hidden">
                    <Card.Body>
                        <div className="responsive">
                            <Card className="custom-card overflow-hidden">
                                <div>
                                    {/* <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "14px",
                                        marginTop: "4px"
                                    }}>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={searchRecord}
                                            onChange={(e) => {
                                                setSearchRecord(e.target.value);
                                                debounceSearch(e.target.value);
                                            }}
                                            style={{
                                                border: "1px solid #ced4da",
                                                borderRadius: "4px",
                                                padding: "0.375rem 0.75rem",
                                            }}
                                        />
                                    </div> */}
                                    <DataTable
                                        data={tableData}
                                        columns={columns}
                                        noHeader
                                        persistTableHead
                                        center={true}
                                        highlightOnHover
                                        striped
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={dataPerPage}
                                        onChangeRowsPerPage={(page, totalRows) => {
                                            handlePerPageChange(page, 1);
                                        }}
                                        onChangePage={handlePageChange}
                                        progressPending={isLoading}
                                        progressComponent={<CircularProgress size={30} style={{ marginTop: "50px" }} />}
                                    />
                                </div>
                            </Card>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default withLoader(Notifications)
