import React, { Fragment, useState } from 'react'
import { Card, Col, Pagination, Row, Table } from 'react-bootstrap'
import withLoader from '../../../layout/loader/withLoader';
import PieChart from '../sales/PieChart';
import Market from '../../../assets/img/image 17 (3).svg'
import brand from '../../../assets/img/brand.png';
import eco from '../../../assets/img/eco.png';
import success from '../../../assets/img/success.png';
import organic from '../../../assets/img/organic.png';
import best from '../../../assets/img/best.png';
import family from '../../../assets/img/family.png';
import grocery from '../../../assets/img/grocery.png';
import logoFood3 from '../../../assets/img/image 14.svg';
import vegan from '../../../assets/img/vegan.png';
import cart from '../../../assets/img/cart.png';
import no1 from '../../../assets/img/no1.png';
import { Select } from '@mui/material';
// import ReceiptModel from '../path-to-ReceiptModel/ReceiptModel';
import { Link, useLocation, useParams } from 'react-router-dom';
import SalesService from '../../../services/sales.service';
import { useEffect } from 'react';
import Loader1 from '../../../../assets/img/svgs/loader.svg'
import ReceiptImgModal from '../../superMarketUser/orders/ReceiptImgModal';
import ReceiptModel from '../orders/ReceiptModel';

const Sales = () => {
    const { state } = useLocation();
    // const { Id, name } = useParams()
    const [Name, setName] = useState(state?.Name)
    const [showReceipt, setShowReceipt] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [saleData, setSaleData] = useState([]);
    const [totalOrder, setTotalOrder] = useState('');
    const [totalIncome, setTotalIncome] = useState('');
    const [totalRows, setTotalRows] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [pieChartData, setPieChartData] = useState([]);
    const [imageCapture, setImageCapture] = useState();
    const [receiptImg, setReceiptImg] = useState()
    const limit = 10;
    const [showDelete, setShowDelete] = useState(false);
    // useEffect(() => {
    //     setName(name)
    // }, [name])

    const handlePageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    };

    const AllData = async () => {
        try {
            setIsLoading(true)
            const response = await SalesService.GetAllSales(pageNumber, limit);
            setIsLoading(false)
            console.log("sales", response.data.data);
            setSaleData(response.data.data)
            setPieChartData(response.data.bestSellingProduct)
            setTotalOrder(response.data.TodayOrder);
            setTotalIncome(response.data.TodayIncome);
            setTotalRows(response.data.totalOrders)
        } catch (e) {
            console.log("e", e);
        }
    }
    useEffect(() => {
        AllData();
        window.scrollTo(0, 0);
    }, [pageNumber]);
    return (

        <Fragment>
            <div className="page-header">
                <h2 className="main-content-title tx-24 mb-4 mt-2">
                    SALES
                </h2>
            </div>
            <Row>
                <div className="row mb-4 summary_cards">
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card">
                            <Card.Body>
                                <div className="card-order ">
                                    <label className="main-content-label font-weight-bold mb-3 pt-1">
                                        Today Order
                                    </label>
                                    <h2 className="text-end card-item-icon card-icon">
                                        <i className="mdi mdi-account-multiple icon-size float-start text-primary"></i>
                                        <span className="font-weight-bold">{totalOrder}
                                        </span>
                                    </h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card">
                            <Card.Body>
                                <div className="card-order ">
                                    <label className="main-content-label font-weight-bold mb-3 pt-1">Today Income
                                    </label>
                                    <h2 className="text-end card-item-icon card-icon">
                                        <i className="mdi mdi-cart-outline icon-size float-start text-primary"></i>
                                        <span className="font-weight-bold">${totalIncome}
                                        </span>
                                    </h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>
            </Row>
            {/* <Row className="row-sm">
                <Col sm={12} lg={12} xl={3}>
                    <Card className="custom-card overflow-hidden">
                        <Card.Header className="border-bottom-0">
                            <label className="main-content-label mb-2">
                                Best-Selling Products
                            </label>
                        </Card.Header>
                        <Card.Body className="ps-12">
                            <PieChart pieChartData={pieChartData} />
                        </Card.Body></Card>
                </Col>
            </Row> */}
            <Row className="row-sm">
                <Col sm={12} lg={12} xl={3}>
                    <Card className="custom-card overflow-hidden">
                        <Card.Header className="border-bottom-0">
                            <label className="main-content-label mb-2">
                                Best-Selling Products
                            </label>
                        </Card.Header>
                        <Card.Body className="ps-12">
                            {pieChartData && pieChartData.length > 0 ? (
                                <PieChart pieChartData={pieChartData} />
                            ) : (
                                <p className="text-center">No data available for chart</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* {showDelete && <ReceiptModel imageCapture={imageCapture} show={showDelete} onHide={() => setShowDelete(false)} />}
            {showReceipt && <ReceiptImgModal receiptImg={receiptImg} show={showReceipt} onHide={() => setShowReceipt(false)} />} */}
            <Row className="row-sm">
                <Col >
                    <Card className="custom-card overflow-hidden">
                        <Card.Header className="border-bottom-0">
                            <label className="main-content-label mb-2">
                                ORDERS
                            </label>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <Table bordered className="text-nowrap table-shopping-cart mb-0">
                                    <thead className="text-muted">
                                        <tr className="small text-uppercase">
                                            <th scope="col">Order ID</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Super Markets</th>
                                            <th scope="col" className="wd-120">
                                                Items
                                            </th>
                                            <th scope="col" className="wd-120">
                                                Amount
                                            </th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" className="text-center ">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (

                                            <div className="loader-overlay">
                                                <img src={Loader1} alt="Loader" height={70} className="text-center" style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', marginTop: "30px" }} />
                                            </div>

                                        )
                                            : saleData.length === 0 ? (
                                                <tr>
                                                    <td colSpan="8" className="text-center">No Any Order available</td>
                                                </tr>
                                            ) : (
                                                <>

                                                    {saleData.map((list, index) => (
                                                        <tr key={index} data-index={index}>
                                                            <td>{list._id}</td>
                                                            <td>{list?.userId?.userName}</td>
                                                            <td>
                                                                <div className="media">
                                                                    <div className="card-aside-img">
                                                                        <img
                                                                            src={list.storeId?.storeImage}
                                                                            alt="img"
                                                                            className="img-sm"
                                                                        />
                                                                    </div>
                                                                    <div className="media-body my-auto">
                                                                        <div className="card-item-desc mt-0">
                                                                            <h6 className="font-weight-semibold mt-0 text-uppercase">
                                                                                {list.storeId?.storeName}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><b>{list.productCount}</b></td>
                                                            <td><b>{"$" + list.amount}</b></td>
                                                            <td>{list.orderDate}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className={`btn btn-sm btn-${list.orderStatus === "Accept" ? "success" : list.orderStatus === "Pending" ? "warning" : "danger"} text-white`}
                                                                    style={{
                                                                        width: "110px",
                                                                        textAlign: "center",
                                                                        cursor: "default",
                                                                    }}
                                                                >
                                                                    <i class={`fa fa-${list.orderStatus === "Accept" ? "check" : list.orderStatus === "Pending" ? "clock" : "close"}`}></i> {list.orderStatus}
                                                                </button>
                                                            </td>

                                                            <td className="text-center">
                                                                <Link to={`/order/details/${list._id}/${list.userId?.userName}`} state={{
                                                                    Id: list._id,
                                                                    Name: list.userId?.userName
                                                                }} className='btn btn-primary btn-sm' >
                                                                    <i className='fas fa-eye'></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                    </tbody>
                                </Table>
                            </div>
                            <Pagination className="mt-3 justify-content-end">
                                <Pagination.Prev onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1} />
                                {Array.from({ length: Math.ceil(totalRows / limit) }).map((_, index) => (
                                    <Pagination.Item key={index + 1} active={index + 1 === pageNumber} onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === Math.ceil(totalRows / limit)} />
                            </Pagination>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default withLoader(Sales)
