

import React from 'react';
import { Modal } from 'react-bootstrap';


const QrCodeImageModal = ({ show, handelDialogOpen, handleDialogClose, qrCodeImage, qrCodeName }) => {
    return (
        <Modal size="large"
            // aria-labelledby="contained-modal-title-vcenter"
            centered show={show}
            onHide={handleDialogClose}>
            <Modal.Header closeButton>
                <Modal.Title>QR Code Image</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {qrCodeImage && <img src={qrCodeImage} alt="QR Code" width="320px" />
                } <h4><b>Token</b> : {qrCodeName}</h4>
            </Modal.Body>
        </Modal>
    );
};

export default QrCodeImageModal;
