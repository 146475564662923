import React, { useState } from 'react'
import useDialogState from '../../../hooks/useDialogState';
import CategorieEditModel from './CategorieEditModel';
import { Button, Card, Col, Pagination, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CategoryService from '../../../services/categories.service';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import DeleteModal from './DeleteModal';
import withLoader from '../../../layout/loader/withLoader';
import { CircularProgress } from '@mui/material';
import Loader1 from '../../../../assets/img/svgs/loader.svg'
const Categories = () => {
    const [showDelete, setShowDelete] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState();
    const [categoriesData, setCategoriesData] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [deleteId, setDeleteId] = useState('');
    const [catName, setCatName] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const limit = 12
    const { open: show, handleClickOpen: handelDialogOpen, handleClickClose: handleDialogClose } = useDialogState()
    console.log("Image", previewImage);
    const initialValue = {
        categoryName: '',
        categoryImage: null
    }
    const validationSchema = Yup.object().shape({
        categoryImage: Yup.mixed().required('Please Select an Image!!'),
        categoryName: Yup.string().required('Please enter the Name!')
    })
    const handleFormSubmit = async (values, action) => {
        console.log("idd", values);
        if (!values._id) {
            try {
                const formData = new FormData();
                formData.append('categoryName', values.categoryName);
                formData.append('categoryImage', values.categoryImage);
                const response = await CategoryService.CreateCategory(formData);
                action.resetForm();
                AllCategories();
                handleDialogClose();
                enqueueSnackbar(response.data.message, {
                    variant: "success", anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                })
                console.log("response", response);
            } catch (e) {
                console.log("e", e);
                enqueueSnackbar(e.response.data.message, {
                    variant: "error", anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                });
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("categoryName", values.categoryName);
                formData.append("categoryImage", values.categoryImage);
                const response = await CategoryService.Update(values._id, formData);
                action.resetForm();
                AllCategories();
                handleDialogClose();
                enqueueSnackbar(response.data.message, {
                    variant: "success", anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                })
                console.log("Update", response.data);
            } catch (e) {
                enqueueSnackbar(e.response.data.message, {
                    variant: "error", anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                });
                console.log("e", e);
            }
        }

    }
    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: validationSchema,
        onSubmit: handleFormSubmit
    })
    const AllCategories = async () => {
        try {
            setIsLoading(true);
            const response = await CategoryService.GetAllCategory(page, limit);
            setIsLoading(false);
            console.log("Response", response.data.data);
            setCategoriesData(response.data.data);
            setTotalRows(response.data.totalCategory)
        } catch (e) {
            console.log("e", e);
        }
    };

    useEffect(() => {
        AllCategories();
        window.scrollTo(0, 0);
    }, [page]);
    const handleImageChange = (e) => {
        const imgFile = e.target.files[0];
        formik.setFieldValue('categoryImage', imgFile);
        console.log("Images", URL.createObjectURL(e.target.files[0]));
        setPreviewImage(URL.createObjectURL(imgFile));
    }
    const handleDialogCloseReset = () => {
        handleDialogClose()
        formik.resetForm();
    }
    const handleDelete = (id, categoryName) => {
        setDeleteId(id);
        setCatName(categoryName);
        setShowDelete(true)
    }
    const handleDialogOpen = async (id) => {
        handelDialogOpen()
        if (id) {
            console.log("id", id);
            setIsUpdate(true);
            const response = await CategoryService.GetOne(id);
            const result = response.data.data
            console.log("update", result);
            formik.setFieldValue("categoryName", result.categoryName);
            formik.setFieldValue("categoryImage", result.categoryImage);
            formik.setFieldValue("_id", result._id)
            setPreviewImage(result.categoryImage);
        } else {
            setIsUpdate(false);
            // formik.setFieldValue("categoryName", "");
            // formik.setFieldValue("categoryImage", "");
            formik.resetForm()
            setPreviewImage(null);
        }
    }
    const nextPage = (page) => {
        setPage(page);
    };
    const previousPage = (page) => {
        setPage(page);
    };

    const pageSize = 12;

    return (
        <>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">CATEGORIES</h2>
                </div>
                <div className="d-flex">
                    <div className="justify-content-center">
                        <Button
                            variant="primary"
                            type="button"
                            className="my-2 btn-icon-text"
                            onClick={() => handleDialogOpen('')}
                        >
                            <i className="fe fe-plus me-2"></i>ADD
                        </Button>
                        {showDelete && <DeleteModal show={showDelete} onHide={() => setShowDelete(false)} catName={catName} deleteId={deleteId} AllCategories={AllCategories} />}
                        {show && (
                            <CategorieEditModel
                                formik={formik} handleImageChange={handleImageChange} previewImage={previewImage}
                                show={show} handelDialogOpen={handelDialogOpen} handleDialogClose={handleDialogCloseReset} isUpdate={isUpdate}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div lg={12} className="w-100">

                <Row className="row row-sm">

                    {/* <div className='d-flex justify-content-end'>
                        <Button
                            variant="primary"
                            type="button"
                            className="my-2 btn-icon-text"
                            onClick={() => handleDialogOpen('')}
                        >
                            <i className="fe fe-plus me-2"></i>ADD
                        </Button>
                    </div> */}
                    {isLoading ? (<img src={Loader1} alt="Loader" height={70} />) : (
                        <>

                            {categoriesData.length === 0 ? (
                                <div className="text-center mt-3 mb-3">
                                    <p>No categories available. Click the "Add" button to create a new category.</p>
                                </div>
                            ) : (<>



                                {categoriesData.map((data, index) => (
                                    <>
                                        <Col sm={6} md={4} xl={2} xs={12} className="mb-5" key={index}>
                                            <div>
                                                <Card className="custom-card mb-1 align-items-center">
                                                    <img
                                                        src={data.categoryImage}
                                                        height={150}
                                                        style={{
                                                            position: "relative",
                                                            objectFit: "cover",
                                                        }}
                                                        alt="brandimage"
                                                        className="Category-img"
                                                    />
                                                    <div style={{ width: "100%" }}>
                                                        <div className='d-flex flex-column align-items-start mx-3'>
                                                            {/* <p className='m-0 tx-12'>{data.item}</p> */}
                                                            <p className='m-0 tx-12'>{`${data.productCount} items`}</p>
                                                            {/* <label className="tx-18 fw-bold">{data.label}</label> */}
                                                            <label className="tx-18 fw-bold">{data.categoryName}</label>
                                                        </div>
                                                        <div className='d-flex align-items-end my-2'>
                                                            <button
                                                                className="ms-auto btn btn-primary btn-sm me-1"
                                                                style={{ height: "28px" }}
                                                                onClick={() => handleDialogOpen(data._id)}
                                                            >
                                                                <i className="fe fe-edit"></i>
                                                            </button>
                                                            <div className="me-2 align-items-baseline">
                                                                <button className='btn btn-danger btn-sm' onClick={() => handleDelete(data._id, data.categoryName)} >
                                                                    <i className='fa fa-trash'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </Col>
                                    </>
                                ))}
                            </>)}
                            </>
                    )}
                            {categoriesData != "" && (
                                <Pagination
                                    className="pagination-circle mb-0 ms-lg-3"
                                    style={{ justifyContent: "center" }}
                                >
                                    <Pagination.Item
                                        onClick={() => previousPage(page - 1)}
                                        disabled={page === 1}
                                    >
                                        <i className="fas fa-angle-double-left"></i>
                                    </Pagination.Item>
                                    {Array.from({ length: Math.ceil(totalRows / limit) }).map(
                                        (_, index) => {
                                            const pageNumber = index + 1;
                                            return (
                                                <Pagination.Item
                                                    onClick={() => nextPage(pageNumber)}
                                                    key={pageNumber}
                                                    active={pageNumber === page}
                                                >
                                                    {pageNumber}
                                                </Pagination.Item>
                                            );
                                        }
                                    )}
                                    <Pagination.Item
                                        onClick={() => nextPage(page + 1)}
                                        disabled={page === Math.ceil(totalRows / limit)}

                                    >
                                        <i className="fas fa-angle-double-right"></i>
                                    </Pagination.Item>
                                </Pagination>
                            )}
                        </Row>
            </div>
        </>
    )
}

export default withLoader(Categories)
