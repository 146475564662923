import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Pagination,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useDialogState from "../../../hooks/useDialogState";
import AddSuperMarketModel from "./AddSuperMarketModel";
import * as Yup from "yup";
import SuperMarketService from "../../../services/supermarket.service";
import { enqueueSnackbar } from "notistack";
import withLoader from "../../../layout/loader/withLoader";
import Loader1 from "../../../../assets/img/svgs/loader.svg";
import DeleteSuperMarket from "./DeleteSuperMarket";

const SuperMarket = () => {
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [cardsData, setCardsData] = useState([]);
  const [totalRows, setTotalRows] = useState();
  const [name, setName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const limit = 12;
  const [previewImage, setPreviewImage] = useState(null);
  const {
    open: show,
    handleClickOpen: handelDialogOpen,
    handleClickClose: handleDialogClose,
  } = useDialogState();

  const initialValue = {
    storeName: "",
    storeImage: null,
    coordinates: "",
  };

  console.log("pageNumber", Math.ceil(totalRows / limit));

  const validationSchema = Yup.object().shape({
    storeImage: Yup.mixed().required("Please Select an Image!"),
    storeName: Yup.string().required("Please enter the Name!"),
    coordinates: Yup.string()
      .required("Please enter the Coordinates!")
      .test(
        "valid-coordinates",
        "Both latitude and longitude are required and must be valid",
        function (value) {
          if (!value) return false; // Check if value exists

          // Split the coordinates by commas and trim spaces
          const parts = value.split(",").map((part) => part.trim());

          // Check if both latitude and longitude are provided
          if (parts.length !== 2) return false;

          const [longitude, latitude] = parts;

          if (!longitude) return false;
          if (!latitude) return false;

          // Validate latitude (-90 to 90) and longitude (-180 to 180)
          const isValidLongitude =
            !isNaN(longitude) && longitude >= -180 && longitude <= 180;
          const isValidLatitude =
            !isNaN(latitude) && latitude >= -90 && latitude <= 90;

          return isValidLongitude && isValidLatitude;
        }
      ),
  });

  const handleFormSubmit = async (values, action) => {
    if (!values._id) {
      try {
        const formData = new FormData();
        formData.append("storeName", values.storeName);
        formData.append("storeImage", values.storeImage);
        // values.coordinates.map((item) =>
        formData.append("coordinates", values.coordinates);

        const response = await SuperMarketService.CreateSuperMarket(formData);
        action.resetForm();
        AllSuperMarkets();
        handleDialogClose();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        const formData = new FormData();
        formData.append("storeName", values.storeName);
        formData.append("storeImage", values.storeImage);
        formData.append("coordinates", values.coordinates);
        const response = await SuperMarketService.Update(values._id, formData);
        action.resetForm();
        AllSuperMarkets();
        handleDialogClose();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  console.log("formik.values", formik.values);

  const handleCoordinateChange = (e) => {
    const input = e.target.value;
    var reg = new RegExp("^[0-9,. ]*$");

    if (reg.test(input)) {
      formik.setFieldValue("coordinates", input);
    }
  };

  // const AllSuperMarkets = async () => {
  //     try {
  //         setIsLoading(true)
  //         const response = await SuperMarketService.GetAllSuperMarkets(page, limit);
  //         setIsLoading(false)
  //         setCardsData(response.data.data)
  //         setTotalRows(response.data.totalStore)
  //     } catch (e) {
  //         console.log("e", e);
  //     }
  // }
  const AllSuperMarkets = async () => {
    try {
      setIsLoading(true);
      const response = await SuperMarketService.GetAllSuperMarkets(page, limit);
      setIsLoading(false);

      if (response.data.totalStore === 0) {
        // No data found, display the message
        setCardsData([]);
        setTotalRows(0);
        console.log(
          "Can't find the Super Market you were looking for? Click here to create a new Super Market"
        );
      } else {
        // Data found, update the state
        setCardsData(response.data.data);
        setTotalRows(response.data.totalStore);
      }
    } catch (e) {
      console.error("Error fetching Super Markets:", e);
    }
  };

  useEffect(() => {
    AllSuperMarkets();
    window.scrollTo(0, 0);
  }, [page]);
  const handleImageChange = (e) => {
    const imgFile = e.target.files[0];
    formik.setFieldValue("storeImage", imgFile);
    console.log("Images", URL.createObjectURL(e.target.files[0]));
    setPreviewImage(URL.createObjectURL(imgFile));
  };
  const viewDetails = (id) => {
    navigate(`/supermarket/product/${id}`);
  };
  const handleDelete = (id, storeName) => {
    setShowDelete(true);
    setDeleteId(id);
    setName(storeName);
  };

  const handleDialogOpen = async (id) => {
    handelDialogOpen();
    if (id) {
      setIsUpdate(true);
      const response = await SuperMarketService.GetOne(id);
      const result = response.data.data;
      formik.setFieldValue("storeName", result.storeName);
      formik.setFieldValue("storeImage", result.storeImage);

      formik.setFieldValue(
        "coordinates",
        String(result.location.coordinates.map((item) => item))
      );
      formik.setFieldValue("_id", result._id);
      setPreviewImage(result.storeImage);
    } else {
      setIsUpdate(false);
      setPreviewImage(null);
      formik.resetForm();
    }
  };
  const handleDialogCloseReset = () => {
    handleDialogClose();
    formik.resetForm();
  };
  const nextPage = (page) => {
    setPage(page);
    setDisplayedData(getCurrentData(page));
    // getAllCars();
    // ScrollToTop();
  };
  const previousPage = (page) => {
    setPage(page);
    setDisplayedData(getCurrentData(page));
    // getAllCars();
    // ScrollToTop();
  };

  const pageSize = 12;
  const getCurrentData = (currentPage) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return cardsData?.slice(startIndex, endIndex);
  };

  const [displayedData, setDisplayedData] = useState(getCurrentData(page));
  // const pageSize = Math.ceil(cardsData.length / 18);
  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">SUPER MARKET</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => handleDialogOpen("")}
            >
              <i className="fe fe-plus me-2"></i>Add
            </Button>
            {showDelete && (
              <DeleteSuperMarket
                show={showDelete}
                onHide={() => setShowDelete(false)}
                name={name}
                deleteId={deleteId}
                AllSuperMarkets={AllSuperMarkets}
              />
            )}
            {show && (
              <AddSuperMarketModel
                formik={formik}
                handleImageChange={handleImageChange}
                previewImage={previewImage}
                show={show}
                handelDialogOpen={handelDialogOpen}
                handleDialogClose={handleDialogCloseReset}
                isUpdate={isUpdate}
                handleCoordinateChange={handleCoordinateChange}
              />
            )}
          </div>
        </div>
      </div>

      <div lg={12} className="w-100">
        <Row className="row row-sm">
          {isLoading ? (
            <img src={Loader1} alt="Loader" height={70} />
          ) : (
            <>
              {cardsData?.length === 0 ? (
                <div className="text-center mt-3 mb-3">
                  <p>
                    No Super Markets available. Click the "Add" button to create
                    a new Super Market.
                  </p>
                </div>
              ) : (
                <>
                  {cardsData?.map((data, index) => (
                    <>
                      <Col
                        sm={6}
                        md={4}
                        xl={2}
                        xs={12}
                        className="mb-5"
                        key={index}
                      >
                        <div>
                          <Card
                            className="custom-card mb-1"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              onClick={() => viewDetails(data._id)}
                              className="align-items-center"
                            >
                              <div className="d-flex justify-content-center">
                                <img
                                  src={data.storeImage}
                                  height={150}
                                  style={{
                                    position: "relative",
                                    objectFit: "cover",
                                  }}
                                  alt="brandimage"
                                  className="brand-image"
                                />
                              </div>
                              <div style={{ width: "100%" }}>
                                <div className="d-flex flex-column">
                                  <div className="d-flex justify-content-center">
                                    <label className="tx-18 fw-bold">
                                      {data.storeName}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-end my-2">
                              <button
                                className="ms-auto btn btn-primary btn-sm me-1"
                                style={{ height: "28px" }}
                                onClick={() => handleDialogOpen(data._id)}
                              >
                                <i className="fe fe-edit"></i>
                              </button>
                              <div className="me-2 align-items-baseline">
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    handleDelete(data._id, data.storeName)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    </>
                  ))}
                </>
              )}
            </>
          )}
          {cardsData != "" && (
            <Pagination
              className="pagination-circle mb-0 ms-lg-3"
              style={{ justifyContent: "center" }}
            >
              <Pagination.Item
                onClick={() => previousPage(page - 1)}
                disabled={page === 1}
              >
                <i className="fas fa-angle-double-left"></i>
              </Pagination.Item>
              {Array.from({ length: Math.ceil(totalRows / limit) }).map(
                (_, index) => {
                  const pageNumber = index + 1;
                  return (
                    <Pagination.Item
                      onClick={() => nextPage(pageNumber)}
                      key={pageNumber}
                      active={pageNumber === page}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  );
                }
              )}
              <Pagination.Item
                onClick={() => nextPage(page + 1)}
                disabled={page === Math.ceil(totalRows / limit)}
              >
                <i className="fas fa-angle-double-right"></i>
              </Pagination.Item>
            </Pagination>
          )}
        </Row>
      </div>
    </>
  );
};

export default withLoader(SuperMarket);
