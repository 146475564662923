import React from 'react'
import { Pie } from 'react-chartjs-2'
const PieChart = (props) => {
    const labels = props.pieChartData.map((item) => item.productName);
    const percentage = props.pieChartData.map((item) => item.percentage);
    const data = {
        labels: labels,
        datasets: [
            {
                data: percentage, // Static data values
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
        ],
    };

    return (
        <div>
            <Pie data={data} className="chart" />
        </div>
    );
};

export default PieChart
