import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "./app/authentication/login";
import MatxLayout from "./app/component/maxLayout";
import NotFound from "./app/layout/sessions/NotFound";
import DashboardPage from "./app/component/admin/dashboard/Dashboard";
import Users from "./app/component/admin/users/Users";
import SuperMarket from "./app/component/admin/superMarket/SuperMarket";
import Product from "./app/component/admin/superMarket/Product";
import Orders from "./app/component/admin/orders/Orders";
import Categories from "./app/component/admin/categories/Categories";
import Brands from "./app/component/admin/brands/Brands";
import ProductDetails from "./app/component/admin/superMarket/ProductDetails";
import Sales from "./app/component/admin/sales/Sales";
import MainOrder from "./app/component/admin/orders/MainOrder";
import Notifications from "./app/component/admin/notifications/Notifications";
import UserDetails from "./app/component/admin/users/UserDetails";
import UserDetailsOrder from "./app/component/admin/users/UserDetailsOrder";
import SDashboard from "./app/component/superMarketUser/dashboard/Dashboard";
import SCategories from "./app/component/superMarketUser/categories/Categories";
import SBrands from "./app/component/superMarketUser/brands/Brands";
import SProducts from "./app/component/superMarketUser/products/Products";
import SProductDetails from "./app/component/superMarketUser/products/ProductDetails";
import SOrders from "./app/component/superMarketUser/orders/Orders";
import SOrdersDetails from "./app/component/superMarketUser/orders/OrderDetails";
import SSales from "./app/component/superMarketUser/sales/Sales";
import SNotifications from "./app/component/superMarketUser/notifications/Notifications";
import MainOrders from "./app/component/teamMember/orders/MainOrders";
import Order from "./app/component/teamMember/orders/Order";

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}
const Email = localStorage.getItem("email");

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: (
          <>
            {Email === "admin@gmail.com" && (
              <RedirectionWrapper to="/dashboard/" />
            )}
            {Email === "supermarketuser@gmail.com" && (
              <RedirectionWrapper to="/store/dashboard/" />
            )}
            {Email === "teammember@gmail.com" && (
              <RedirectionWrapper to="/team/orders/" />
            )}
          </>
        ),
      },

      {
        path: "/dashboard/",
        element: <DashboardPage />,
      },
      {
        path: "/users/",
        element: <Users />,
      },
      {
        path: "/users/details/:Id/",
        element: <UserDetails />,
      },
      {
        path: "/users/:order/",
        // element: <UserDetailsOrder />,
        element: <Orders />,
      },
      {
        path: "/supermarket",
        element: <SuperMarket />,
      },
      {
        path: "/categories",
        element: <Categories />,
      },
      {
        path: "/brands",
        element: <Brands />,
      },
      {
        path: "/supermarket/product/:storeId",
        element: <Product />,
      },
      {
        path: "/supermarket/peoductdetails/:productId/",
        element: <ProductDetails />,
      },
      {
        path: "/sales",
        element: <Sales />,
      },
      {
        path: "/sales/details/:Id/:name", // hrsd change
        element: <Sales />,
      },
      {
        path: "/order",
        element: <MainOrder />,
      },
      {
        path: "/order/details/:Id/:name",
        element: <Orders />,
      },
      {
        path: "sales/details/:userName",
        element: <Orders />,
      },
      {
        path: "/notifications",
        element: <Notifications />,
      },
      {
        path: "/notifications/:userName",
        element: <Notifications />,
      },
      {
        path: "/team/orders/:id",
        element: <MainOrders />,
      },
      {
        path: "/team/orders/:userName",
        element: <Order />,
      },
      {
        path: "/store/dashboard/",
        element: <SDashboard />,
      },
      {
        path: "/store/categories/",
        element: <SCategories />,
      },
      {
        path: "/store/brands/",
        element: <SBrands />,
      },
      {
        path: "/store/products",
        element: <SProducts />,
      },
      {
        path: "/store/products/:Id/",
        element: <SProductDetails />,
      },
      {
        path: "/store/orders",
        element: <SOrders />,
      },
      {
        path: "/store/orders/:Id",
        element: <SOrdersDetails />,
      },
      {
        path: "/store/sales",
        element: <SSales />,
      },
      {
        path: "/store/sales/:Id",
        element: <SOrdersDetails />,
      },
      {
        path: "/store/notifications",
        element: <SNotifications />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <>
        {Email === "admin@gmail.com" && <RedirectionWrapper to="/dashboard/" />}
        {Email === "supermarketuser@gmail.com" && (
          <RedirectionWrapper to="/store/dashboard/" />
        )}
        {Email === "teammember@gmail.com" && (
          <RedirectionWrapper to="/team/orders/" />
        )}
      </>
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
