import React, { useState } from 'react'
import { Card, Pagination, Table } from 'react-bootstrap';
import Loader1 from '../../../../assets/img/svgs/loader.svg'
import { Link } from 'react-router-dom';
import StoreOrdersService from '../../../services/storeOrders.service';
import { useEffect } from 'react';

const Orders = () => {
    const [showDelete, setShowDelete] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const limit = 10
    const handlePageChange = (page) => {
        setPage(page);
    };
    const AllOrders = async () => {
        try {
            setIsLoading(true);
            const response = await StoreOrdersService.GetAllOrders(page, limit);
            // setTimeout(() => {
            setIsLoading(false);
            // }, 5000000);
            console.log("Response", response.data.data);
            setOrderData(response.data.data);
            setTotalRows(response.data.totalOrders)
        } catch (e) {
            console.log("e", e);
        }
    };

    useEffect(() => {
        AllOrders();
    }, [page]);
    return (
        <>
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">ORDERS</h2>
                </div>
            </div>
            <div lg={12} className="w-100">
                {/* {showDelete && <ReceiptModel show={showDelete} onHide={() => setShowDelete(false)} />} */}
                <Card className="custom-card overflow-hidden">
                    <Card.Body>
                        <div className="table-responsive">
                            <Table bordered className="text-nowrap table-shopping-cart mb-0">
                                <thead className="text-muted">
                                    <tr className="small text-uppercase">
                                        <th scope="col">ID</th>
                                        <th scope="col">User Name</th>
                                        {/* <th scope="col">Super Markets</th> */}
                                        <th scope="col" className="wd-120">
                                            Items
                                        </th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Token</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" className="text-center ">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderData.length === 0 ? (
                                        <tr>
                                            <td colSpan="8" className="text-center">No Any Order available</td>
                                        </tr>
                                    ) : (
                                        <>
                                            {orderData.map((list, index) => (
                                                <tr key={index} data-index={index}>
                                                    <td>{list._id}</td>
                                                    <td>{list.userId?.userName}</td>
                                                    {/* <td>
                                                    <div className="media">
                                                        <div className="card-aside-img">
                                                            <img
                                                                src={list.storeId.storeImage}
                                                                alt="img"
                                                                className="img-sm"
                                                            />
                                                        </div>
                                                        <div className="media-body my-auto">
                                                            <div className="card-item-desc mt-0">
                                                                <h6 className="font-weight-semibold mt-0 text-uppercase">
                                                                    {list.storeId.storeName}
                                                                </h6>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td> */}
                                                    <td>{list.productCount}</td>
                                                    <td>{list.orderDate}
                                                    </td>
                                                    <td>{list.token}</td>
                                                    <td>
                                                        <button
                                                            className={`btn btn-sm btn-${list.orderStatus === "Accept" ? "success" : list.orderStatus === "Pending" ? "warning" : "danger"} text-white`}
                                                            style={{
                                                                width: "110px",
                                                                textAlign: "center",
                                                                cursor: "default",
                                                            }}
                                                        >
                                                            <i class={`fa fa-${list.orderStatus === "Accept" ? "check" : list.orderStatus === "Pending" ? "clock" : "close"}`}></i> {list.orderStatus}
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        <Link to={`/store/orders/${list._id}`} state={{
                                                            Id: list._id,
                                                            Name: list.userId?.userName
                                                        }} className='btn btn-primary btn-sm' >
                                                            <i className='fas fa-eye'></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>)}
                                </tbody>
                            </Table>
                        </div>
                        <Pagination className="mt-3 justify-content-end">
                            <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} />
                            {Array.from({ length: Math.ceil(totalRows / limit) }).map((_, index) => (
                                <Pagination.Item key={index + 1} active={index + 1 === page} onClick={() => handlePageChange(index + 1)}>
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === Math.ceil(totalRows / limit)} />
                        </Pagination>
                    </Card.Body>
                </Card>
            </div >
        </>
    );
}

export default Orders
