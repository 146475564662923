import React from 'react'
import { Modal } from 'react-bootstrap'

const ReceiptImgModal = (props) => {
    const { receiptImg } = props
    return (
        <>
            <Modal
                {...props}
                // show={showDelete}
                onHide={props.onHide}
                size="large"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton >
                    <Modal.Title> <p className='fs-25 mb-0'>Image</p></Modal.Title></Modal.Header>
                <Modal.Body className="text-center">
                    <img src={receiptImg} width={'100%'} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReceiptImgModal
