import serverCall from '../../serverCall'

const GraphData = async (data) => {
    try {
        const response = await serverCall.get('/users/superMarketUser/graph', data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}

const StoreDashboardService = {
    GraphData,
};
export default StoreDashboardService;