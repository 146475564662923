import React from 'react'
import { useState } from 'react';
import { Fragment } from 'react';
import { Breadcrumb, Button, Card, Col, Pagination, Row, Table } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Receipt from '../../../assets/img/Receipt.png';
import { Select } from 'antd';
import ReceiptModel from './ReceiptModel';
import OrderService from '../../../services/order.service';
import { useEffect } from 'react';
import ReceiptImgModal from '../../superMarketUser/orders/ReceiptImgModal';

//get notifcation sawer page all order
const Orders = () => {
    const { state } = useLocation();
    const { Id, name } = useParams()
    const [detailsOrder, setDetailsOrder] = useState([])
    const [imageCapture, setImageCapture] = useState([])
    const [showReceipt, setShowReceipt] = useState(false);
    const [Name, setName] = useState(state?.Name)
    const [receiptImg, setReceiptImg] = useState()

    useEffect(() => {
        setName(name)
    }, [name])

    console.log("imageCapture", Id);

    const [showDelete, setShowDelete] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    console.log("status", selectedValue);

    // const { Id, Name } = useParams();
    // console.log("HRSID", Id);


    const handleView = (label, img) => {
        setShowDelete(true);

        if (label === "PRODUCT") {
            setImageCapture(img);
        } else {
            setImageCapture(receiptImg);
        }
    };

    const handleStatusChange = async (newValue) => {
        try {
            const response = await OrderService.StatusUpdate({
                cartId: Id,
                status: newValue
            })
        } catch (e) {
            console.log("e", e);
        }
        setSelectedValue(newValue);
    };
    const DetailsOrder = async () => {
        try {
            const response = await OrderService.OrderDetails(Id);
            const result = response.data.data.productList
            setDetailsOrder(result);
            setImageCapture(result.map((item) => item.captureImage))
            const Detail = response.data.data;
            setSelectedValue(Detail.orderStatus)
            setReceiptImg(Detail.receipt)
            console.log("Imagsdaddsadasde", Detail);
        } catch (e) {
            console.log("e", e);
        }
    }
    useEffect(() => {
        DetailsOrder();
        window.scrollTo(0, 0);
    }, [Id])
    console.log("DetailsOffer", detailsOrder);

    return (
        <>
            {/* <!-- Page Header --> */}
            <div className="page-header">
                <div>
                    <h2 className="main-content-title tx-24 mg-b-5">ORDER({Name})</h2>
                </div>
            </div>
            <div lg={12} className="w-100">
                {showDelete && <ReceiptModel imageCapture={imageCapture} show={showDelete} onHide={() => setShowDelete(false)} />}
                {showReceipt && <ReceiptImgModal receiptImg={receiptImg} show={showReceipt} onHide={() => setShowReceipt(false)} />}
                <Card className="custom-card overflow-hidden">
                    <Card.Body>
                        <div className="table-responsive">
                            <div className="select-pending d-flex justify-content-end mb-2">
                                {selectedValue === "Pending" &&
                                    <Select
                                        value={selectedValue}
                                        onChange={(newValue) => handleStatusChange(newValue)}
                                        // onChange={(newStatus) => handleStatusChange(row.invoiceId)}
                                        style={{ width: "120px" }}
                                        className="custom-select-1"
                                    >
                                        <option
                                            value="Accept"
                                            className="text-white mb-1"
                                            style={{ backgroundColor: "#15A552" }}
                                        >
                                            <i className="fa fa-check"></i> {"ACCEPTED"}
                                        </option>
                                        <option
                                            value="Pending"
                                            className="d-none"
                                            style={{ backgroundColor: "#F1911D" }}
                                        >
                                            <div>
                                                <i className="fa fa-clock"></i> {"PENDING"}
                                            </div>
                                        </option>
                                        <option
                                            value="Reject"
                                            className="text-white "
                                            style={{ backgroundColor: "#fd6074" }}
                                        >
                                            <i className="fa fa-close"></i> {"REJECTED"}
                                        </option>
                                    </Select>}
                                {selectedValue === "Accept" && <button
                                    className="btn btn-sm btn-success text-white"
                                    style={{
                                        width: "110px",
                                        textAlign: "center",
                                        cursor: "default",
                                    }}
                                >
                                    <i class="fa fa-check"></i> ACCEPTED
                                </button>
                                }
                                {selectedValue === "Reject" && <button
                                    className="btn btn-sm btn-danger text-white"
                                    style={{
                                        width: "110px",
                                        textAlign: "center",
                                        cursor: "default",
                                    }}
                                >
                                    <i class="fa fa-close"></i> REJECTED
                                </button>}
                            </div>
                            <Table bordered className="text-nowrap table-shopping-cart mb-0">
                                <thead className="text-muted">
                                    <tr className="small text-uppercase">
                                        <th scope="col">Items</th>
                                        <th scope="col">Offer</th>
                                        <th scope="col" className="wd-120">
                                            Quantity
                                        </th>
                                        <th scope="col" className="wd-120">
                                            Price
                                        </th>
                                        <th scope="col" className="text-center ">
                                            Image
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detailsOrder && detailsOrder?.map((list, index) => (
                                        <tr key={index} data-index={index}>
                                            <td>
                                                <div className="media">
                                                    <div className="card-aside-img">
                                                        <img
                                                            src={list?.productId?.productImage}
                                                            alt="img"
                                                            className="img-sm"
                                                        />
                                                    </div>
                                                    <div className="media-body my-auto">
                                                        <div className="card-item-desc mt-0">
                                                            <h6 className="font-weight-semibold mt-0 text-uppercase">
                                                                {list?.productId?.productName}
                                                            </h6>
                                                            <dl className="card-item-desc-1">
                                                                {/* <dt>{list.productId.description} </dt> */}
                                                                <dd>{list?.productId?.description}</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td> <span className="price font-weight-bold tx-16">
                                                {list.offerId ? list?.offerId?.discount : "-"}
                                            </span></td>
                                            <td>{list.qty}
                                            </td>
                                            <td>
                                                <div className="price-wrap">
                                                    <span className="price font-weight-bold tx-16">
                                                        {list?.productId?.price}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <button className='btn btn-primary btn-sm' onClick={() => handleView("PRODUCT", list.captureImage)} >
                                                    <i className='fas fa-receipt'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <div className="media">
                                                <div className="card-aside-img">
                                                    <img
                                                        src={Receipt}
                                                        alt="img"
                                                        className="img-sm"
                                                    />
                                                </div>
                                                <div className="media-body my-auto">
                                                    <div className="card-item-desc mt-0">
                                                        <h6 className="font-weight-semibold mt-0 text-uppercase">
                                                            RECEIPT
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td> <span className="price font-weight-bold tx-16">
                                            -
                                        </span></td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <div className="price-wrap">
                                                <span className="price font-weight-bold tx-16">
                                                    -
                                                </span>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <button className='btn btn-primary btn-sm' onClick={() => handleView("RECEIPT")} >
                                                <i className='fas fa-receipt'></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default Orders
