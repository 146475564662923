import serverCall from '../../serverCall'

const GetAllOrders = async (page, limit, data) => {
    try {
        const response = await serverCall.get(`/cart/superMarketUser/get-all-orders?page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}

const StoreOrdersService = {
    GetAllOrders,
};
export default StoreOrdersService;