import React, { Fragment, useState } from 'react'
import { Card, Col, Pagination, Row, Table } from 'react-bootstrap';
import Loader1 from '../../../../assets/img/svgs/loader.svg'
import { Link } from 'react-router-dom';
import StoreSalesService from '../../../services/storeSale.service'
import { useEffect } from 'react';
import PieChart from './PieChart';
import ReceiptModel from '../../admin/orders/ReceiptModel';
const Sales = () => {
    const [showReceipt, setShowReceipt] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [saleData, setSaleData] = useState([]);
    const [pieChartData, setPieChartData] = useState([]);
    const [totalOrder, setTotalOrder] = useState('');
    const [totalIncome, setTotalIncome] = useState('');
    const [totalRows, setTotalRows] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [receiptImg, setReceiptImg] = useState()

    const limit = 10;

    const handlePageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    };
    const AllData = async () => {
        try {
            setIsLoading(true);
            const response = await StoreSalesService.GetAllSales(pageNumber, limit);
            setIsLoading(false);
            console.log("API Response:", response.data);
            setSaleData(response?.data?.data);
            setPieChartData(response?.data?.bestSellingProductStoreWise);
            setTotalOrder(response?.data?.TodayOrder);
            setTotalIncome(response?.data?.TodayIncome);
            setTotalRows(response?.data?.totalOrders);
            setReceiptImg(response.receipt)
        } catch (e) {
            console.log("Error fetching data:", e);
        }
    }
    useEffect(() => {
        AllData();
    }, [pageNumber]);

    return (

        <Fragment>
            <div className="page-header">
                <h2 className="main-content-title tx-24 mb-4 mt-2">
                    SALES
                </h2>
            </div>
            <Row>
                <div className="row mb-4 summary_cards">
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card">
                            <Card.Body>
                                <div className="card-order ">
                                    <label className="main-content-label font-weight-bold mb-3 pt-1">
                                        Today Order
                                    </label>
                                    <h2 className="text-end card-item-icon card-icon">
                                        <i className="mdi mdi-account-multiple icon-size float-start text-primary"></i>
                                        <span className="font-weight-bold">{totalOrder}
                                        </span>
                                    </h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3}>
                        <Card className="custom-card">
                            <Card.Body>
                                <div className="card-order ">
                                    <label className="main-content-label font-weight-bold mb-3 pt-1">Today Income
                                    </label>
                                    <h2 className="text-end card-item-icon card-icon">
                                        <i className="mdi mdi-cart-outline icon-size float-start text-primary"></i>
                                        <span className="font-weight-bold">{totalIncome}
                                        </span>
                                    </h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>
            </Row>
            <Row className="row-sm">
                <Col sm={12} lg={12} xl={3}>
                    <Card className="custom-card overflow-hidden">
                        <Card.Header className="border-bottom-0">
                            <label className="main-content-label mb-2">
                                Best-Selling Products
                            </label>
                        </Card.Header>
                        <Card.Body className="ps-12">
                            {pieChartData && pieChartData.length > 0 ? (
                                <PieChart pieChartData={pieChartData} />
                            ) : (
                                <p className="text-center">No data available for chart</p>
                            )}
                        </Card.Body></Card>
                </Col>
            </Row>
            {showReceipt && <ReceiptModel show={showReceipt} onHide={() => setShowReceipt(false)} />}
            <Row className="row-sm">
                <Col >
                    <Card className="custom-card overflow-hidden">
                        <Card.Header className="border-bottom-0">
                            <label className="main-content-label mb-2">
                                ORDERS
                            </label>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <Table bordered className="text-nowrap table-shopping-cart mb-0">
                                    <thead className="text-muted">
                                        <tr className="small text-uppercase">
                                            <th scope="col">Order ID</th>
                                            <th scope="col">User Name</th>
                                            {/* <th scope="col">Super Markets</th> */}
                                            <th scope="col" className="wd-120">
                                                Items
                                            </th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" className="text-center ">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {saleData.length === 0 ? (
                                            <tr>
                                                <td colSpan="8" className="text-center">No Any Order available</td>
                                            </tr>
                                        ) : (
                                            <>

                                                {saleData.map((list, index) => (
                                                    <tr key={index} data-index={index}>
                                                        <td>{list._id}</td>
                                                        <td>{list?.userId?.userName}</td>

                                                        <td>{list?.productCount}</td>
                                                        <td>{list?.orderDate}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className={`btn btn-sm btn-${list?.orderStatus === "Accept" ? "success" : list?.orderStatus === "Pending" ? "warning" : "danger"} text-white`}
                                                                style={{
                                                                    width: "110px",
                                                                    textAlign: "center",
                                                                    cursor: "default",
                                                                }}
                                                            >
                                                                <i class={`fa fa-${list?.orderStatus === "Accept" ? "check" : list?.orderStatus === "Pending" ? "clock" : "close"}`}></i> {list.orderStatus}
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <Link to={`/store/sales/${list._id}`} state={{
                                                                Id: list._id,
                                                                Name: list?.userId?.userName
                                                            }} className='btn btn-primary btn-sm' >
                                                                <i className='fas fa-eye'></i>
                                                            </Link>
                                                            {/* <button className='btn btn-primary btn-sm' onClick={() => { handleView() }} >
                                                            <i className='fas fa-receipt'></i>
                                                        </button> */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            <Pagination className="mt-3 justify-content-end">
                                <Pagination.Prev onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1} />
                                {Array.from({ length: Math.ceil(totalRows / limit) }).map((_, index) => (
                                    <Pagination.Item key={index + 1} active={index + 1 === pageNumber} onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === Math.ceil(totalRows / limit)} />
                            </Pagination>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Sales
