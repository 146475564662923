import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as yup from "yup";
import AddUser from "./AddUserModal";
import { DeleteUserModal } from "./DeleteUserModal";
import DataTable from "react-data-table-component";
import Loader1 from "../../../../assets/img/svgs/loader.svg";
import UserService from "../../../services/user.service";
import withLoader from "../../../layout/loader/withLoader";
import useDialogState from "../../../hooks/useDialogState";
import { enqueueSnackbar } from "notistack";

const Users = () => {
  const [id, setId] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [emailUse, setEmailUse] = useState("");
  const [userRole, setUserRole] = useState("");
  const [searchRecord, setSearchRecord] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [totalRows, setTotalRows] = useState();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [userData, setUserData] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    open: show,
    handleClickOpen: handelDialogOpen,
    handleClickClose: handleDialogClose,
  } = useDialogState();

  const initialValues = {
    email: "",
    password: "",
    userType: "",
  };
  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is Required"),
    password: yup.string().min(8).required("Password is required!"),
    userType: yup.string().required("Roles is required"),
  });
  const handleDialogCloseReset = () => {
    handleDialogClose();
    formik.resetForm();
  };
  const handleSubmitForm = async (values, action) => {
    console.log("storeId", values.storeId);
    const { email, password, userType, storeId } = values;
    console.log("values", values);
    try {
      const response = await UserService.CreateUser(
        email,
        password,
        userType,
        storeId
      );
      action.resetForm();
      AllUsers();
      handleDialogClose();
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      console.log("user", response.data.data);
    } catch (e) {
      console.log("e", e);
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });

  const handleOpen = async (id) => {
    handelDialogOpen();
  };
  const AllUsers = async () => {
    try {
      setIsLoading(true);
      const response = await UserService.GetAllUsers(
        searchValue,
        pageNumber,
        dataPerPage
      );
      console.log("Response", response.data.data);
      setUserData(response.data.data);
      setTotalRows(response.data.totalUser);
    } finally {
      setIsLoading(false);
    }
  };
  const ListOfStore = async () => {
    try {
      const response = await UserService.StoreList();
      setStoreList(response.data.data);
      console.log(response.data.data);
    } catch (e) {
      console.log("e", e);
    }
  };
  const BrandsOptions = [
    ...storeList.map((item) => ({
      value: item._id,
      label: item.storeName,
    })),
  ];
  useEffect(() => {
    AllUsers();
    ListOfStore();
    window.scrollTo(0, 0);
  }, [searchValue, pageNumber, dataPerPage]);
  const handleDeleteNodal = () => setDeleteModal(false);

  const DEBOUNCE_THRESHOLD = 500;
  const timeoutHandler = useRef(null);
  const debounceSearch = (event) => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    // setIsLoading(true)
    timeoutHandler.current = setTimeout(() => {
      setsearchValue(event);
      // setIsLoading(false)
    }, DEBOUNCE_THRESHOLD);
  };
  const handlePageChange = async (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    await AllUsers();
    setIsLoading(false);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    await AllUsers();
    setIsLoading(false);
  };
  const handleSelectRoleChange = (value) => {
    setUserRole(value);
    console.log("role", value);
    formik.setFieldValue("userType", value);
  };

  const columns = [
    {
      name: <b>FIRST NAME</b>,
      selector: (row) => (row.firstName ? row.firstName : "-"),
      sortable: true,
    },
    {
      name: <b>LAST NAME</b>,
      selector: (row) => (row.lastName ? row.lastName : "-"),
      sortable: true,
    },
    {
      name: <b>ROLE</b>,
      selector: (row) => (row.userType ? row.userType : "-"),
      sortable: true,
    },
    {
      name: <b>EMAIL</b>,
      selector: (row) => (row.email ? row.email : "-"),
      sortable: true,
    },
    {
      name: <b>COUNTRY CODE</b>,
      selector: (row) => (row.countryCode ? `+${row.countryCode}` : "-"),
      sortable: true,
    },
    {
      name: <b>PHONE NO.</b>,
      selector: (row) => (row.mobile ? row.mobile : "-"),
      sortable: true,
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <div className="d-flex">
          <div>
            <Link
              to={`/users/details/${row._id}`}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">USERS</h2>
        </div>
        <div className="d-flex align-items-center">
          <div className="">
            <Button
              variant="primary"
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => handleOpen("")}
            >
              <i className="fe fe-user-plus me-2"></i> Add
            </Button>
            {show && (
              <AddUser
                show={show}
                handleDialogClose={handleDialogCloseReset}
                handleSelectRoleChange={handleSelectRoleChange}
                BrandsOptions={BrandsOptions}
                userRole={userRole}
                formik={formik}
                id={id}
              />
            )}
            {
              <DeleteUserModal
                deleteModal={deleteModal}
                onHide={handleDeleteNodal}
                deleteId={deleteId}
                setDeleteModal={setDeleteModal}
                emailUse={emailUse}
              />
            }
          </div>
        </div>
      </div>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "14px",
                      marginTop: "4px",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchRecord}
                      onChange={(e) => {
                        setSearchRecord(e.target.value);
                        debounceSearch(e.target.value);
                      }}
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "4px",
                        padding: "0.375rem 0.75rem",
                      }}
                    />
                  </div>
                  <DataTable
                    data={userData}
                    columns={columns}
                    noHeader
                    persistTableHead
                    center={true}
                    highlightOnHover
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <img src={Loader1} alt="Loader" height={70} />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withLoader(Users);
