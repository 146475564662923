import serverCall from '../../serverCall'

const CreateProduct = async (data) => {
    try {
        const response = await serverCall.post("/product/admin/create-product", data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const GetAllProducts = async (id, page, limit, data) => {
    try {
        const response = await serverCall.get(`/product/get-product-storedWise?storeId=${id}&page=${page}&limit=${limit}`, data);
        return response
    } catch (e) {
        console.log("GetAllProdcthrsd", e);
    }
}
const GetOne = async (id) => {
    try {
        const response = await serverCall.get(`/product/get-product/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const Update = async (id, data) => {
    try {
        const response = await serverCall.patch(`/product/admin/update-product/${id}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const Delete = async (id) => {
    try {
        const response = await serverCall.delete(`/product/admin/delete-product/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const CreateOffer = async (data) => {
    try {
        const response = await serverCall.post(`/offer/admin/create-offer`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const GetOneOffer = async (id) => {
    try {
        const response = await serverCall.get(`/offer/admin/get-offer/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const UpdateOffer = async (id, data) => {
    try {
        const response = await serverCall.patch(`/offer/admin/update-offer/${id}`, data);
        return response
    } catch (e) {
        console.log("e", e);
    }
}
const DeleteOffer = async (id) => {
    try {
        const response = await serverCall.delete(`/offer/admin/delete-offer/${id}`);
        return response
    } catch (e) {
        console.log("e", e);
    }
}

const ProductService = {
    CreateProduct,
    GetAllProducts,
    GetOne,
    Update,
    Delete,
    CreateOffer,
    GetOneOffer,
    UpdateOffer,
    DeleteOffer
};
export default ProductService;